/**********************************************************
 * Imports
 *********************************************************/

import moment from 'moment.lib';
import {
	cloneDeep,
	extend,
	groupBy
} from 'lodash';


/**********************************************************
 * State and Base Configs
 *********************************************************/

/**
 * A locale for moment must be created for each possible start day of the week
 * we want to support. The locale inherits the default parent's config.
 * Any locales used are just in the context of the grid and are not global
 */
const LOCALE_MAP = {
	saturday: 'bhr-grid-start-saturday',
	sunday: 'bhr-grid-start-sunday',
	monday: 'bhr-grid-start-monday'
};

let currentLocale = moment.locale();
moment.defineLocale(LOCALE_MAP.saturday, {parentLocale: currentLocale, week: {dow: 6}});
moment.defineLocale(LOCALE_MAP.sunday, {parentLocale: currentLocale, week: {dow: 0}});
moment.defineLocale(LOCALE_MAP.monday, {parentLocale: currentLocale, week: {dow: 1}});
moment.locale(currentLocale); // Set the global locale back to the original


/**
 * The standard options that can be overridden when creating a new grid
 */
const BASE_OPTIONS = {
	/**
	 * Only matters when the view type is "month"
	 * It will return the range, and grid with the trailing days from the previous and next month
	 * Example:
	 * when FALSE
	 *     1,2...31
	 *
	 * when TRUE
	 *     28,29,30,1,2...31,1,2
	 */
	trail: true,

	/**
	 * Determines if the grid should come back bundled in groups of weeks. Example:
	 * when TRUE
	 *     [
	 *         [1/1/17, ..., 1/7/17],
	 *         [1/8/17, ..., 1/14/17]
	 *     ]
	 *
	 * when FALSE
	 *     [
	 *         1/1/17,
	 *         ...,
	 *         1/31/17
	 *     ]
	 */
	groupByWeek: true,

	/**
	 * Determines what view the range should be in. Possible values are "month", "2weeks", and "1week"
	 * When the view changes from month to a week format, it will default to the first available week(s)
	 * of that month, UNLESS, the current month is in view, then it will default to the view that contains
	 * the current date
	 */
	view: 'month',

	/**
	 * Forces the calendar to use a specific day as the start of the week.
	 * If not set, than the calendar will default to whatever locale moment is using the browser
	 * The current options are:
	 *  - saturday
	 *  - sunday
	 *  - monday
	 */
	startOfWeek: null,

	/**
	 * Whether or not to show weekends...
	 */
	showWeekends: true,

	/**
	 * If you are looking at a range that contains "today", and you change the view to something
	 * smaller than a month, it will place that week containing today as the first week of the range
	 */
	smartWeekView: true,

	/**
	 * If "today" is in the last week of the month, and that week contains days for the next month,
	 * changing the view to month will result in the last week of the current month as the first
	 * week in the new view
	 */
	smartMonthView: false
};


/**********************************************************
 * CalendarGrid Class
 *********************************************************/

export default class CalendarGrid {

	constructor(options = {}) {
		this._options = extend({}, BASE_OPTIONS, options);

		this._now = moment().startOf('day');
		this._setLocaleOnMomentDate(this._now);

		this._rangeStart = null;
		this._rangeEnd = null;
		this._marker = this._now.clone();
		this._setupMarker();
		this._setRange();
	}


	/**
	 * Returns the grid based on the current range
	 *
	 * @returns Array
	 */
	grid() {
		let range = this.constructor.buildRange(this._rangeStart, this._rangeEnd);

		if (!this._options.showWeekends) {
			range = range.filter(day => [0, 6].indexOf(day.day()) === -1);
		}

		if (this._options.groupByWeek) {
			range = this.constructor.groupRangeByWeek(range);
		}

		return range;
	}


	/**
	 * Check whether or not the current day is between (inclusive) the start and end of the range
	 *
	 * @returns Boolean
	 */
	isTodayInRange() {
		return this.constructor.isBetweenInclusive(this._now, this._rangeStart, this._rangeEnd);
	}


	/**
	 * Returns the current start and end of the range but as moment instances
	 *
	 * @returns {{start, end}}
	 */
	momentRange() {
		return {
			start: this._rangeStart.clone(),
			end: this._rangeEnd.clone()
		};
	}


	/**
	 * Get the range via a specified format
	 *
	 * @param format
	 * @returns {{start, end}}
	 */
	formattedRange(format = moment.defaultFormat) {
		return {
			start: this._rangeStart.format(format),
			end: this._rangeEnd.format(format)
		};
	}


	/**
	 * Changes the range based on the view type passed.
	 * If going from "month" to either "2weeks" or "1week" the range should change
	 * to the first 2 weeks, or first week of the month UNLESS the current day inside
	 * of the current range. If the current day is inside, then the view defaults to
	 * the week with that day in it as the first week shown
	 *
	 * @param type
	 * @returns {CalendarGrid}
	 */
	setView(type = this._options.view) {
		this._options.view = type;

		if (this._options.view === 'month') {
			this._marker.endOf('week');
		}

		if (this._options.view === '1week' || this._options.view === '2weeks') {
			if (this._options.smartWeekView && this.constructor.isBetweenInclusive(this._now, this._rangeStart, this._rangeEnd)) {
				this._marker = this._now.clone().startOf('week');
			} else {
				this._marker.startOf('week');
			}
		}

		return this.jumpTo(this._marker);
	}


	/**
	 * Sets the current range to include today
	 *
	 * @returns {CalendarGrid}
	 */
	today() {
		return this.jumpTo(this._now);
	}


	/**
	 * Jumps to a range where the date passed is included. The date is cloned to avoid setting a locale
	 *
	 * @param momentDate
	 * @returns {CalendarGrid}
	 */
	jumpTo(momentDate) {
		momentDate = momentDate.clone();
		this._setLocaleOnMomentDate(momentDate);
		this._marker = momentDate;
		this._setupMarker();
		this._setRange();

		return this;
	}


	/**
	 * Increments the current range.
	 *
	 * @returns {CalendarGrid}
	 */
	next() {
		switch (this._options.view) {
			case 'month':
				this._marker.add('1', 'month');
				break;
			case '2weeks':
				this._marker.add('2', 'weeks');
				break;
			case '1week':
				this._marker.add('1', 'week');
				break;
		}

		this._setRange();
		return this;
	}


	/**
	 * Decrements the current range.
	 *
	 * @returns {CalendarGrid}
	 */
	prev() {
		switch (this._options.view) {
			case 'month':
				this._marker.subtract('1', 'month');
				break;
			case '2weeks':
				this._marker.subtract('2', 'weeks');
				break;
			case '1week':
				this._marker.subtract('1', 'weeks');
				break;
		}

		this._setRange();
		return this;
	}


	/**
	 * Updates whether or not the grid should show weekends
	 *
	 * @param bool
	 * @returns {CalendarGrid}
	 */
	toggleWeekends(bool = !this._options.showWeekends) {
		this._options.showWeekends = !!bool;
		return this;
	}


	/**
	 * Clone the instance of CalendarGrid
	 *
	 * @returns new {CalendarGrid}
	 */
	clone() {
		return cloneDeep(this);
	}


	_setupMarker() {
		if (!this._marker) {
			this._marker = this._now.clone();
		}

		switch (this._options.view) {
			case 'month':
				if (this._options.smartMonthView) {
					this._marker.endOf('week');
				}
				this._marker.startOf('month');
				break;


			// 2weeks and 1week use the same logic
			case '2weeks':
			case '1week':
				this._marker.startOf('week');
				break;
		}
	}


	/**
	 * Updates the range based on the marker
	 *
	 * @private
	 */
	_setRange() {
		switch (this._options.view) {
			case 'month':
				this._rangeStart = this._marker.clone();
				this._rangeEnd = this._marker.clone().endOf('month');
				if (this._options.trail) {
					this._rangeStart.startOf('week');
					this._rangeEnd.endOf('week');
				}
				break;
			case '2weeks':
				this._rangeStart = this._marker.clone().startOf('week');
				this._rangeEnd = this._rangeStart.clone().add('13', 'days');
				break;
			case '1week':
				this._rangeStart = this._marker.clone().startOf('week');
				this._rangeEnd = this._rangeStart.clone().add('6', 'days');
				break;
		}
	}


	/**
	 * Sets the "week starts on" locale on the date object.
	 *
	 * @param momentDate
	 * @private
	 */
	_setLocaleOnMomentDate(momentDate) {
		if (this._options.startOfWeek) {
			let locale = LOCALE_MAP[this._options.startOfWeek.toLowerCase()];

			if (locale) {
				momentDate.locale(locale);
			}
		}
	}


	/**
	 * Builds a range of days based on two moment objects
	 *
	 * @param momentStart
	 * @param momentEnd
	 * @returns {Array}
	 */
	static buildRange(momentStart, momentEnd) {
		const [startYear, startMonth, startDay] = momentStart.toArray();
		const [endYear, endMonth, endDay] = momentEnd.toArray();
		const range = [];
		const day = moment.utc([startYear, startMonth, startDay]);
		const rangeEnd = moment.utc([endYear, endMonth, endDay]);

		while (day.isSameOrBefore(rangeEnd, 'day')) {
			const [y, m, d] = day.toArray();
			range.push(moment([y, m, d]));
			day.add(1, 'd');
		}

		return range;
	}


	/**
	 * Groups a range of moment objects based on their week.
	 * There must be a sort for ranges that cross a year mark
	 *
	 * @param arrayOfMoments
	 * @returns Array
	 */
	static groupRangeByWeek(arrayOfMoments) {
		let groupedRange = groupBy(arrayOfMoments, day => day.week());

		return Object
			.keys(groupedRange)
			.reduce((a, b) => [...a, groupedRange[b]], [])
			.sort((a, b) => a[0].diff(b[0]));
	}


	/**
	 * Checks if a moment date is between two other moment dates inclusively
	 *
	 * @param momentToCheck
	 * @param momentStart
	 * @param momentEnd
	 * @returns {*}
	 */
	static isBetweenInclusive(momentToCheck, momentStart, momentEnd) {
		return momentToCheck.isBetween(momentStart, momentEnd, null, '[]');
	}
}
