import { Flex, IconV2, Table, TextButton, Tooltip } from '@bamboohr/fabric';
import { isEnabled } from 'FeatureToggle.util';
import { takeToMultiEinEditBankPage } from '../../utils/shared';

import {
	AccountDetails,
	plaidRenderStatus,
	renderStatus,
} from '../multi-ein-banks-table/utils';
import './multi-banks-table.styl';

export function MultiBanksTable(props) {
	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);
	const COLUMNS = [
		{
			header: $.__('Account Nickname/Type'),
			cell: (row) => {
				const { accountNickname, bankName, clientId, bankId } = row;

				const name = accountNickname ? accountNickname : bankName;

				return (
					<AccountDetails
						bankId={bankId}
						clientId={clientId}
						primaryText={name}
						secondaryText={row.accountType}
						takeToMultiEinEditBankPage={takeToMultiEinEditBankPage}
					/>
				);
			},
		},
		{
			header: $.__('Account Info'),
			cell: (row) => (
				<AccountDetails
					primaryText={`Account #: ${row.accountNumber}`}
					secondaryText={`Routing #: ${row.routingNumber}`}
				/>
			),
		},
		{
			header: (
				<Tooltip
					content={$.__(
						'This includes a penny test, and giving BambooHR authorization to debit from your account.',
					)}
					placement="top"
				>
					<Flex alignItems="center" flexDirection="row" gap="4px">
						{$.__('Status')}

						<IconV2 name="circle-question-regular" size={12} />
					</Flex>
				</Tooltip>
			),
			cell: (row) =>
				renderStatus(
					row,
					isPlaidAdoptionAndSufficientBalanceEnabled,
					props.handleOpenVerificationModal,
					props.handleOpenUploadModal,
					props.handleOpenDownloadModal,
				),
		},
		{
			header: (
				<Tooltip
					content={$.__(
						'This is used to check account balance and ensure your team is paid on time.',
					)}
					placement="top"
				>
					<Flex alignItems="center" flexDirection="row" gap="4px">
						Plaid
						<IconV2 name="circle-question-regular" size={12} />
					</Flex>
				</Tooltip>
			),
			cell: (row) =>
				plaidRenderStatus(
					row,
					props.plaidLinkToken,
					props.handleLinkSuccess,
					props.handlePlaidUpdateSuccess,
				),
			align: 'center',
		},
	];

	const LEGACY_COLUMNS = [
		{
			header: $.__('Account Nickname'),
			cell: (row) => renderBankName(row),
			sortBy: (row) => row.accountNickname,
		},
		{
			header: $.__('Account #'),
			cell: (row) => <div>{row.accountNumber}</div>,
			sortBy: (row) => row.accountNumber,
		},
		{
			header: $.__('Routing #'),
			cell: (row) => <div>{row.routingNumber}</div>,
		},
		{
			header: $.__('Account Type'),
			cell: (row) => <div>{row.accountType}</div>,
		},
		{
			header: $.__('Status'),
			cell: (row) =>
				renderStatus(
					row,
					isPlaidAdoptionAndSufficientBalanceEnabled,
					props.handleOpenVerificationModal,
					props.handleOpenUploadModal,
					props.handleOpenDownloadModal,
				),
		},
	];

	const renderBankName = (row) => {
		const { accountNickname, bankName, clientId, bankId } = row;

		const name = accountNickname ? accountNickname : bankName;

		return (
			<span className="MultiBanksTable__companyLegalName">
				<TextButton
					clickAction={() => takeToMultiEinEditBankPage(clientId, bankId)}
					text={name}
					type="button"
				/>
			</span>
		);
	};

	const { company } = props;

	return (
		<Table
			caption={$.__('List of Employees')}
			columns={
				isPlaidAdoptionAndSufficientBalanceEnabled ? COLUMNS : LEGACY_COLUMNS
			}
			rowKey={(row) => {
				return row.bankId ? row.bankId : row.clientId;
			}}
			rows={company.bankAccounts}
		/>
	);
}
