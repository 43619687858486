import { extent, min, max } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import { scaleLinear, scaleTime } from 'd3-scale';
import { area, line } from 'd3-shape';
import { timeDay } from 'd3-time';
import { defaultsDeep } from 'lodash';

import { mouseOver } from 'Charts.mod/event-functions';
import { isEnabled } from 'FeatureToggle.util';

import BaseChart from './base-chart';

import './area-chart.styl';
import { select } from 'd3-selection';

/* Example Contract
'surveyCompletionChart': {
	'type': 'areaChart',
	'key': 'surveyCompletionChart',
	'data': {
		'areas': [
			{
				'points': [
					{
						'x': '2018-06-25T00:00:00-06:00',
						'y': 0
					},
					{
						'x': '2018-06-26T18:00:00-06:00',
						'y': 25
					},
					{
						'x': '2018-06-29T09:00:00-06:00',
						'y': 50
					},
					{
						'x': '2018-07-01T20:00:00-06:00',
						'y': 75
					}
				]
			}
		],
		'xAxisExtraInfo': [
			{
				'event': 'launch',
				'text': 'Survey Launch',
				'x': '2018-06-25T00:00:00-06:00'
			},
			{
				'event': 'email',
				'text': 'Reminder email',
				'x': '2018-06-28T00:00:00-06:00'
			},
			{
				'event': 'email',
				'text': 'Last chance email',
				'x': '2018-06-30T00:00:00-06:00'
			}
		]
	}
},
*/

const CLASS_NAME = 'AreaChart';
const DEFAULT_SETTINGS = {
	chartClass: '',
	container: {
		height: 200,
		width: 400,
		margin: {
			top: 10,
			right: 10,
			bottom: 30,
			left: 0
		}
	},
	xAxisTickCount: timeDay.every(1),
	xAxisTickFormat: moment.shortFormat,
	yAxisLabelWidth: 40,
	yAxisMin: null,
	yAxisMax: null,
	pathStrokeWidth: 2,
	drawEndDot: false
};
const JADE = isEnabled('jade');

export default class AreaChart extends BaseChart {
	constructor(selector, settings = DEFAULT_SETTINGS) {
		super();

		this._selector = selector;
		this._CLASS_NAME = CLASS_NAME;
		this._settings = defaultsDeep({}, settings, DEFAULT_SETTINGS);

		this._validateRequirements();
		this._createRoot();
	}

	_minYDrawnValue = 0;

	_data;

	draw(data) {
		this._data = data;
		const { areas, xAxisExtraInfo } = data;

		this._areas = areas;
		this._xAxisExtraInfo = xAxisExtraInfo;

		const allXPoints = areas
			.map(val => val.points.map(val => val.x))
			.reduce((acc, val) => acc.concat(val));

		const allYPoints = areas
			.map(val => val.points.map(val => val.y))
			.reduce((acc, val) => acc.concat(val));

		const settings = this._settings;

		const minYPadded = settings.yAxisMin === null ? Math.floor(min(allYPoints) / 10) * 10 : settings.yAxisMin;
		const maxYPadded = settings.yAxisMax === null ? Math.ceil(max(allYPoints) / 10) * 10 : settings.yAxisMax;

		this._minYDrawnValue = minYPadded;

		this._xScale = this._getXScale(allXPoints);
		this._yScale = this._getYScale(minYPadded, maxYPadded);

		this._drawAreas();
		this._drawXAxis(allXPoints);
		this._drawXAxisExtraInfo();
		this._drawYAxis();
	}

	resize() {
		select(this._selector).select('div').remove();
		this._createRoot();
		this.draw(this._data);
	}

	_getXScale(data) {
		const { container: { margin }, yAxisLabelWidth } = this._settings;
		return scaleTime()
			.domain(extent(data, d => new Date(d)))
			.range([yAxisLabelWidth, this._getContainerWidth() - margin.right]);
	}

	_getYScale(minY, maxY) {
		const { container: { height, margin } } = this._settings;
		return scaleLinear()
			.domain([minY, maxY]).nice()
			.range([height, margin.top]);
	}

	_getYScaleText(value) {
		const { yAxisUseWholeValue } = this._settings;
		return `${ value }${ yAxisUseWholeValue ? '' : '%' }`;
	}

	_getColor(areaObj, index) {
		const settings = this._settings;
		if (settings.colors) {
			areaObj.color = settings.colors[index];
		}
		return areaObj.color ? areaObj.color : '';
	}

	_drawAreas() {
		this._drawAreaGroups();
		this._drawLineGroups();
	}

	_removeTrailingNullPoints(points) {
		const lastDefinedPoint = points.reduce((prev, current, i) => ((current && current.y) ? i : prev),0);
		return points.filter((point, i) => i <= lastDefinedPoint);
	}

	_drawAreaGroups() {
		const svg = this._svg;

		const areaGroupUpdate = svg
			.selectAll(this._getClassList('__areaGroup'))
			.style('display', 'initial')
			.data(this._areas);

		const areaGroupEnter = areaGroupUpdate
			.enter()
			.append('g')
			.attr('class', `${ this._buildClassList('__areaGroup') }`)
			.attr('id', (d, i) => {
				const color = this._getColor(d, i);
				return color ? `tag${ color.slice(1) }_${ i }` : '';
			});

		const areaGroupMerge = areaGroupUpdate.merge(areaGroupEnter);

		const areaElem = area()
			.x(d => this._xScale(new Date(d.x)))
			.y0(() => {
				return (this._minYDrawnValue ? this._yScale(this._minYDrawnValue) : this._yScale(0));
			})
			.y1(d => this._yScale(d.y));

		areaGroupEnter
			.append('path')
			.attr('class', `${ (JADE) ? 'AreaChart--themeFill' : 'baseFillColor' } ${ this._buildClassList('__area') }`)
			.style('fill', (d, i) => this._getColor(d, i));

		areaGroupMerge
			.select(this._getClassList('__area'))
			.attr('d', d => areaElem(this._removeTrailingNullPoints(d.points)));

		areaGroupUpdate
			.exit()
			.remove();
	}

	_drawLineGroups() {
		const svg = this._svg;
		const settings = this._settings;

		const lineGroupUpdate = svg
			.selectAll(this._getClassList('__lineGroup'))
			.style('display', 'initial')
			.data(this._areas);

		const lineGroupEnter = lineGroupUpdate
			.enter()
			.append('g')
			.attr('class', `${ this._buildClassList('__lineGroup') }`)
			.attr('id', (d, i) => {
				const color = this._getColor(d, i);
				return color ? `tag${ color.slice(1) }_${ i }` : '';
			});

		const lineGroupMerge = lineGroupUpdate.merge(lineGroupEnter);

		const lineElem = line()
			.x(d => this._xScale(new Date(d.x)))
			.y(d => this._yScale(d.y));

		lineGroupEnter
			.append('path')
			.attr('class', `${ (JADE) ? 'AreaChart--themeStroke' : 'baseStrokeColor' } ${ this._buildClassList('__line') }`)
			.style('fill', 'none')
			.style('stroke', (d, i) => this._getColor(d, i))
			.style('stroke-width', settings.pathStrokeWidth + 'px');

		if (settings.drawEndDot) {
			const formatPoint = ({x, y}) => ({ x: this._xScale(new Date(x)), y: this._yScale(y) });
			const getLastPoint = (data) => ((data && data.points) ? formatPoint(this._removeTrailingNullPoints(data.points).pop()) : { x: this._xScale(0), y: this._yScale(0) });
			lineGroupEnter
				.append('circle')
				.attr('class', `${ (JADE) ? 'AreaChart--themeFill' : 'baseFillColor' } ${ this._buildClassList('__endDot') }`)
				.attr('fill', this._getColor.bind(this))
				.attr('pointer-events', 'none')
				.attr('r', 4)
				.attr('cx', d => getLastPoint(d).x)
				.attr('cy', d => getLastPoint(d).y);
		}

		lineGroupMerge
			.select(this._getClassList('__line'))
			.attr('d', d => lineElem(this._removeTrailingNullPoints(d.points)));

		lineGroupUpdate
			.exit()
			.remove();
	}

	_drawXAxis(allXPoints) {
		const { container: { height }, xAxisTickCount, xAxisTickFormat } = this._settings;
		const svg = this._svg;

		this._xScale.domain(extent(allXPoints.map(date => this._resetTimeValues(date)), d => this._resetTimeValues(d)));

		const xAxis = axisBottom(this._xScale)
			.ticks(xAxisTickCount)
			.tickFormat(d => moment(d).format(xAxisTickFormat));

		// If axis currently exists just update it, otherwise create it
		const isAxisEmpty = svg
			.select(this._getClassList('__xAxis'))
			.empty();

		if (isAxisEmpty) {
			svg.append('g')
				.attr('class', this._buildClassList('__xAxis'))
				.attr('transform', `translate(0, ${ height })`)
				.call(xAxis);
		} else {
			svg.select(this._getClassList('__xAxis'))
				.call(xAxis);
		}
	}

	_drawXAxisExtraInfo() {
		if (this._xAxisExtraInfo) {
			const svg = this._svg;
			const xAxisExtraInfoUpdate = svg
				.selectAll(this._getClassList('__xAxisExtraInfo'))
				.data(this._xAxisExtraInfo);

			const xAxisExtraInfoEnter = xAxisExtraInfoUpdate
				.enter()
				.append('g')
				.attr('class', this._buildClassList('__xAxisExtraInfo'))
				.on('mouseover', mouseOver);

			const xAxisExtraInfoMerge = xAxisExtraInfoUpdate.merge(xAxisExtraInfoEnter);

			xAxisExtraInfoUpdate
				.exit()
				.remove();

			this._drawXAxisExtraInfoLine(xAxisExtraInfoEnter, xAxisExtraInfoMerge);
			this._drawXAxisExtraInfoCircle(xAxisExtraInfoEnter, xAxisExtraInfoMerge);
			this._drawXAxisExtraInfoIcon(xAxisExtraInfoEnter, xAxisExtraInfoMerge);
		}
	}

	_drawXAxisExtraInfoLine(xAxisExtraInfoEnter, xAxisExtraInfoMerge) {
		const { container: { height, margin } } = this._settings;

		const lineTop = height + margin.bottom - 33;
		const lineBottom = lineTop + 14;

		xAxisExtraInfoEnter
			.append('line')
			.attr('class', this._buildClassList('__xAxisExtraInfoLine'));

		xAxisExtraInfoMerge
			.select(this._getClassList('__xAxisExtraInfoLine'))
			.attr('x1', d => this._xScale(this._resetTimeValues(d.x)))
			.attr('x2', d => this._xScale(this._resetTimeValues(d.x)))
			.attr('y1', lineTop)
			.attr('y2', lineBottom)
			.style('stroke', '#d8d8d8')
			.style('stroke-width', '1px');
	}

	_drawXAxisExtraInfoCircle(xAxisExtraInfoEnter, xAxisExtraInfoMerge) {
		const { container: { height, margin } } = this._settings;

		xAxisExtraInfoEnter
			.append('circle')
			.attr('class', this._buildClassList('__xAxisExtraInfoCircle'));

		xAxisExtraInfoMerge
			.select(this._getClassList('__xAxisExtraInfoCircle'))
			.attr('cx', d => this._xScale(this._resetTimeValues(d.x)))
			.attr('cy', height + margin.bottom - 10)
			.attr('r', 10)
			.style('fill', '#fff')
			.style('stroke', '#d8d8d8')
			.style('stroke-width', '1px');
	}

	_drawXAxisExtraInfoIcon(xAxisExtraInfoEnter, xAxisExtraInfoMerge) {
		const { container: { height, margin } } = this._settings;

		xAxisExtraInfoEnter
			.append('svg')
			.attr('class', this._buildClassList('__xAxisExtraInfoIcon'));

		xAxisExtraInfoMerge
			.select(this._getClassList('__xAxisExtraInfoIcon'))
			.attr('height', 12)
			.attr('width', 12)
			.attr('x', d => this._xScale(this._resetTimeValues(d.x)) - 6)
			.attr('y', height + margin.bottom - 16)
			.style('fill', '#b1b1b1')
			.append('svg:use')
			.attr('xlink:href', (d) => {
				return this._getIcon(d.event);
			});
	}

	_getIcon(event) {
		const events = {
			launch: '#fab-star-13x12',
			email: '#fab-envelope-12x10',
		};

		return events[event];
	}

	_drawYAxis() {
		const { container: { margin, width }, yAxisLabelWidth } = this._settings;
		const svg = this._svg;
		const yAxis = axisLeft(this._yScale)
			.ticks(5)
			.tickFormat(d => this._getYScaleText(d));

		// Calculate the actual width if it's set to 'auto'
		let actualWidth = width;
		if (width === 'auto' && this._selector) {
			actualWidth = this._selector.clientWidth || this._selector.getBoundingClientRect().width;
		}

		// If axis currently exists just update it, otherwise create it
		const isAxisEmpty = svg
			.select(this._getClassList('__yAxis'))
			.empty();

		if (isAxisEmpty) {
			svg.append('g')
				.attr('transform', `translate(${ yAxisLabelWidth })`)
				.attr('class', this._buildClassList('__yAxis'))
				.call(yAxis);
		} else {
			svg.select(this._getClassList('__yAxis'))
				.call(yAxis);
		}

		svg.select(this._getClassList('__yAxis'))
			.selectAll('line')
			.attr('x2', () => actualWidth - yAxisLabelWidth - margin.right)
			.attr('style', (d, i, a) => {
				if (i === 0) {
					return 'stroke-dasharray: 0';
				}
			});
	}

	_resetTimeValues(date) {
		const dateObj = new Date(date);
		return dateObj.setHours(0, 0, 0, 0);
	}
}
