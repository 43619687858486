import { Fragment, ReactElement, useState } from 'react';
import Linkify from 'linkify-react';
import { Button, Checkbox, Flex, HeroHeadlineSize, StandardModal, TextButton } from '@bamboohr/fabric';
interface Props {
	contents: string;
}

//
// [
//   {domain: 'example.com', exp: 1234567890},
//   {domain: 'example2.com', exp: 1234567890}
// ]
//
function getDomains(){
	const domains = localStorage.getItem('externalLinkPopoverTemporarilyAllowedDomains');
	if(domains){
		const domainArray = JSON.parse(domains);
		const now = Date.now();
		// filter out any expired domains
		return domainArray.filter((domainObject: {domain: string, exp: number}) => {
			return domainObject.exp > now;
		})
	}
	return [];
}

function pushDomain(domain: string) {
	const domains = getDomains();
	const domainObject = {
		domain: domain,
		exp: Date.now() + 1000 * 60 * 60 * 24 // 24 hours
	}
	domains.push(domainObject);
	localStorage.setItem('externalLinkPopoverTemporarilyAllowedDomains', JSON.stringify(domains));
}

export function LinkifyText(props: Props): ReactElement {
	const {
		contents = '',
	} = props;

	const [isOpen, setIsOpen] = useState(false);
	const [allowDomain, setAllowDomain] = useState(false);
	const [link, setLink] = useState('');

	const checkAllowed = (link: string) => {
		const currentUrl = new URL(window.location.href);
		const url = new URL(link);
		// Check if the link is in the allowed domains or is the current domain
		return getDomains().find(obj => obj.domain == url.hostname) || currentUrl.hostname === url.hostname;
	}

	const navigateToLink = () => {
		const url = new URL(link);
		if(allowDomain){
			pushDomain(url.hostname);
			setAllowDomain(false);
		}

		window.open(link, '_blank');
		setIsOpen(false);
	}

	const linkProps = {
		onClick: (event) => {
			event.preventDefault();
			event.stopPropagation();
			const link = event.target.href;
			if(checkAllowed(link)){
				window.open(link, '_blank');
				return;
			}

			setLink(link);
			setIsOpen(true);
		}
	}

	return (
		<Fragment>
			<Linkify options={{ attributes: linkProps }}>
				{contents}
			</Linkify>

			<StandardModal
			isOpen={ isOpen }
			onRequestClose={ () => {
				setIsOpen(false);
			} }
			>
				<StandardModal.Body
						renderFooter={
							<StandardModal.Footer
								actions={
									[
										<TextButton key="button-1" onClick={ () => {setIsOpen(false)}}>
											Cancel
										</TextButton>,
										<Button key="button-2" onClick={navigateToLink}>
											Continue
										</Button>,
									]
								}
							/>
						}
				>
					<Flex marginTop={'32px'}/>
					<StandardModal.HeroHeadline
						icon="arrow-up-right-from-square-regular"
						text={$.__('You are now leaving BambooHR')}
					>
						<div className={"fab-marginTop--8"}>{$.__('You are being redirected to an external site, click continue to proceed.')}</div>
						<Flex justifyContent={'center'} marginTop={'24px'} marginBottom={'32px'}>
							<Checkbox
								value='allow-domain-checkbox'
								onChange={() => setAllowDomain(!allowDomain)}
								checked={allowDomain}
								label={$.__('Trust this site temporarily')}
							/>
						</Flex>
					</StandardModal.HeroHeadline>
				</StandardModal.Body>
			</StandardModal>
		</Fragment>
	);
}
