import { render } from 'base/wrapped-render';
import { useState } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { PoMicroFrontend } from 'micro-frontend.react';

function PoEditTimeOffRequestModalWrap({ employee, timeOffRequestId, canCancelRequest, onRequestEditOrCancel, onCloseComplete }) {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<PoMicroFrontend
			canCancelRequest={canCancelRequest}
			employee={employee}
			isOpen={isOpen}
			onCloseComplete={onCloseComplete}
			onRequestClose={(action) => {
				setIsOpen(false);
				if (action === 'edited' || action === 'cancelled') {
					onRequestEditOrCancel();
				}
			}}
			route='/time-off/edit-time-off-modal'
			timeOffRequestId={timeOffRequestId}
		/>
	);
}

export function showEditTimeOffRequestModal(employee, timeOffRequestId, canCancelRequest, finishedUrl) {
	const editTimeOffRequestModalContainer = document.createElement('div');
	// The element needs to be in the DOM for the `MicroFrontend` component to render properly.
	document.body.appendChild(editTimeOffRequestModalContainer);

	const onRequestEditOrCancel = () => {
		if (finishedUrl) {
			window.location = finishedUrl;
		} else {
			window.location.reload();
		}
	}

	render(
		<PoEditTimeOffRequestModalWrap
			canCancelRequest={canCancelRequest}
			employee={employee}
			onCloseComplete={() => unmountComponentAtNode(editTimeOffRequestModalContainer)}
			onRequestEditOrCancel={onRequestEditOrCancel}
			timeOffRequestId={timeOffRequestId}
		/>,
		editTimeOffRequestModalContainer
	);

}

export function commentExistsInADenialModal() {
	let elements = ['.denialModal #manager_note', '.denialModal #comment'],
		post = false;

	for (let i = 0; i < elements.length; i++) {
		if ($(elements[i]).length) {
			if ($.trim($(elements[i]).val())) {
				post = true;
			}
		}
	}
	return post;
}