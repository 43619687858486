import Ajax from '@utils/ajax';

const FILE_UPLOAD_URL = '/files/post_upload_url';

export interface GetFileUploadInfoResponse {
	/**
	 * The file ID
	 */
	fileInfo: string;
	/**
	 * Additional form inputs to send with the file
	 */
	formInputs: Record<string, string>;
	/**
	 * The URL to upload the file to
	 */
	url: string;
}

export interface FileUploadInfo {
	/**
	 * The file ID
	 */
	fileId: string;
	/**
	 * Form data to send with the file
	 */
	formData: FormData;
	/**
	 * The URL to upload the file to
	 */
	url: string;
}

/**
 * Gets info needed to upload a file.
 */
export async function getFileUploadInfo(
	formData = new FormData(),
): Promise<FileUploadInfo> {
	const {
		data: { fileInfo, formInputs, url },
	} = await Ajax.get<GetFileUploadInfoResponse>(FILE_UPLOAD_URL);

	Object.entries(formInputs).forEach(([key, value]) =>
		formData.append(key, value),
	);

	return { url, fileId: fileInfo, formData };
}
