import { TemporaryFile } from 'file-list.react';
import { noop } from 'lodash';
import { getUploadFileXhr } from 'http/File.service';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const READY_STATE_DONE = 4;
const UPLOAD_ERROR = $.__('Error when uploading file, please try again. If the issue persists, please contact support.');

/**
 * upload a temporary file
 *
 * @param {File interface} file - file to upload
 * @param {uploadTempFile~onLoadStart} onLoadStart - request fired
 * @param {uploadTempFile~onProgress} onProgress - fired periodically to indicate progress
 * @param {uploadTempFile~onLoad} onLoad - upload completed successfully
 * @param {uploadTempFile~onError} onError - request aborted or returned "ERROR" status
 */
export const uploadTempFile = (
	file,
	onLoadStart = noop,
	onProgress = noop,
	onLoad = noop,
	onError = noop,
) => {
	let fileId;
	const data = new FormData();
	data.append('upload', file);

	const xhr = new XMLHttpRequest();

	xhr.addEventListener('loadstart', (e) => {
		onLoadStart(xhr);
	});

	xhr.upload.addEventListener('progress', (e) => {
		onProgress(Math.round(e.loaded / e.total * 100));
	});

	xhr.upload.addEventListener('abort', (e) => {
		onError();
	});

	xhr.addEventListener(
		'load',
		ifFeature(
			'files_api_v2_enabled',
			() => {
				onLoad(
					TemporaryFile({
						remoteId: fileId,
						name: file.name,
						progress: 100,
					}),
				);
			},
			(e) => {
				const response = JSON.parse(e.target.responseText);

				if (response.status === 'ERROR') {
					window.setMessage(response.message, 'error');
					onError();
				} else {
					onLoad(
						TemporaryFile({
							remoteId: response.fid,
							name: response.name,
							progress: 100,
						}),
					);
				}
			},
		),
	);

	/* @startCleanup files_api_v2_enabled */
	if (ifFeature('files_api_v2_enabled', true)) {
		/* @endCleanup files_api_v2_enabled */
		getUploadFileXhr().then((res) => {
			// eslint-disable-next-line prefer-destructuring
			fileId = res.fileId;
			res.uploadFile(file, {
				xhr: () => xhr,
			});
		});
		/* @startCleanup files_api_v2_enabled */
	} else {
		xhr.open('POST', '/ajax/files/attachTemporary.php', true);
		xhr.send(data);
	}
	/* @endCleanup files_api_v2_enabled */

	xhr.onerror = function (e) {
		window.setMessage(UPLOAD_ERROR, 'error');
		window.Rollbar.error('FEEDS: Error when uploading temporary file', e);
	};
};

/**
 * delete a temporary file
 *
 * @param {TemporaryFile} file - file to delete
 */
export const deleteTempFile = (file) => {
	if (
		file.xhr &&
		file.xhr.readyState &&
		file.xhr.readyState !== READY_STATE_DONE
	) {
		file.xhr.abort();
	} else {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', `/ajax/dialog/employee_files/delete_files.php?temporary=1&file_ids[]=${ file.remoteId }&delete=1&showMessage=no`, true);
		xhr.setRequestHeader('X-CSRF-Token', window.CSRF_TOKEN);
		xhr.send();
	}
};
