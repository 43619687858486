import { Fragment, useState } from 'react';

import { Modal } from 'modal-legacy';
import { deleteItem } from './utils';

/**
 * @param {string} props.fieldTitle
 * @param {function} props.closeModal
 * @param {boolean} props.isOpen
 * @param {string} props.label
 * @param {number} props.listId
 * @param {number} props.listValueId
 * @param {number} props.totalHistoryCount
 */
export function DeleteModal(props) {
	const { fieldTitle, closeModal, isOpen, label, listId, listValueId, totalHistoryCount } = props;

	const [isProcessing, setIsProcessing] = useState(false);

	const deleteAction = () => {
		setIsProcessing(true);

		deleteItem(listId, listValueId, totalHistoryCount)
			.then(() => window.location.reload())
			.catch(() => {
				window.errorFallBack();
				setIsProcessing(false);
			});
	};

	return (
		<Fragment>
			<Modal
				headline={$.__('Are you sure you want to delete the "%s" %s?', label, fieldTitle)}
				isOpen={isOpen}
				isProcessing={isProcessing}
				onClose={closeModal}
				primaryAction={deleteAction}
				primaryActionText={$.__('Yes, Delete %s', fieldTitle)}
				title={null}
				type='aggressiveConfirmation'
			/>
		</Fragment>
	);
}
