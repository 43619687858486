import {
	Checkbox,
	Flex,
	FloatingIconButton,
	IconButton,
	IconV2,
	Label,
	MaskedInput,
	TextButton,
	TextField,
} from '@bamboohr/fabric';
import { CircleX14x14, ClockReverse14x13, TriangleExclamation13x11 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classNames from 'classnames';
import {
	noop,
} from 'lodash';
import {
	Fragment,
	useEffect,
	useState,
} from 'react';
import Tooltip from 'tooltip.react';


import {
	formatAmount,
	getFieldClasses,
	getTooltipSettings,
	getTotalRateString,
	handleFieldWithMaxChange,
	handleRateBlur,
	handleRateChange,
} from './utils';

import './code-rate-row.styl';

import {
	RateHistoryModal,
} from '../../rate-history-modal';

export function CodeRateRow(props) {
	const {
		allDisabled = false,
		codeRateId,
		code,
		description,
		employerRate,
		employeeRate,
		hasError = false,
		showRemoveBtn = false,
		handleRemoveClick,
		isActive = true,
		history,
		hide,
		futureChange = '',
		isLast = false,
		handleActiveChange,
		isCodesRatesEdit = false,
	} = props;

	// State-related vars
	const [newCode, setNewCode] = useState(code || '');
	const [newDescription, setNewDescription] = useState(description || '');
	const [newEmployerRate, setNewEmployerRate] = useState(0);
	const [newEmployeeRate, setNewEmployeeRate] = useState(0);
	const [total, setTotal] = useState(0);
	const [modalOpen, setModalOpen] = useState(false);

	// Other vars
	const onRemoveClick = showRemoveBtn && typeof handleRemoveClick === 'function' ? handleRemoveClick : noop;
	const disableCodeAndDescription = isCodesRatesEdit && codeRateId > 0;
	const checkboxFieldClasses = classNames(
		{
			hidden: !isCodesRatesEdit,
			'CodeRateRow__field--checkbox': isCodesRatesEdit,
		}
	);
	const historyDividerClasses = classNames(
		'CodeRateRow__historyDivider',
		{ 'CodeRateRow__historyDivider--last': isLast }
	);
	const codeRateRowClasses = classNames(
		'CodeRateRow',
		{ 'CodeRateRow--hidden': hide }
	);

	useEffect(() => {
		setNewEmployerRate(formatAmount(employerRate || 0));
		setNewEmployeeRate(formatAmount(employeeRate || 0));
		setTotal(getTotalRateString(employerRate, employeeRate));
	}, []);

	return (
		<div className={ codeRateRowClasses }>
			{/* @startCleanup encore */}
			{ showRemoveBtn && (
				<span className="CodeRateRow__remove">
					{ifFeature(
						'encore',
						<Fragment />,
						<FloatingIconButton
							clickAction={ () => onRemoveClick(codeRateId) }
							icon={ <CircleX14x14/> }
							secondary={ true }
							type="button"
						/>
					)}
				</span>
			) }
			{/* @endCleanup encore */}
			<span className={ checkboxFieldClasses }>
				<Checkbox
					checked={ isActive }
					id={ `codeRateField.${codeRateId}.isActive` }
					name={ `codeRateField.${codeRateId}.isActive` }
					onChange={ (event) => handleActiveChange(codeRateId, event.checked) }
					value={ isActive }
				/>
			</span>

			<span className="CodeRateRow__formField CodeRateRow__field CodeRateRow__field--addMargin CodeRateRow__field--code">
				<MaskedInput
					MaskOptions={{
						mask: '0000-00',
						placeholderChar: '0',
					}}
					conformInitialValue={false}
					disabled={allDisabled || disableCodeAndDescription}
					name={`codeRateField.${codeRateId}.code`}
					onChange={({ target: { value } }) => {
						setNewCode(value);
					}}
					placeholder="0000-00"
					status={getFieldClasses(hasError, newCode) ? 'error' : undefined}
					submitUnmaskedValue={true}
					value={newCode}
					width={4}
				/>
			</span>
			<span className="CodeRateRow__formField CodeRateRow__field CodeRateRow__field--addMargin">
				<TextField 
					disabled={ allDisabled || disableCodeAndDescription }
					name={ `codeRateField.${ codeRateId }.description` }
					onChange={ event => handleFieldWithMaxChange(event.target.value, 50, setNewDescription) }
					status={ getFieldClasses(hasError, newDescription) ? 'error' : undefined}
					value={ newDescription }
					width={ 8 }
				/>
			</span>
			<span className="CodeRateRow__formField CodeRateRow__field">
				<TextField 
					disabled={ allDisabled }
					name={ `codeRateField.${ codeRateId }.employerRate` }
					onBlur={ (event) => {
						handleRateBlur(event, newEmployeeRate, setNewEmployerRate, setTotal);
					} }
					onChange={ (event) => {
						handleRateChange(event, setNewEmployerRate);
					} }
					placeholder="$"
					status={ hasError && newEmployerRate === "$0.00" ? 'error' : undefined }
					value={ newEmployerRate }
					width={ 4 }
				/>
			</span>
			<span className="CodeRateRow__formField">
				+
			</span>
			<span className="CodeRateRow__formField CodeRateRow__field">
				<TextField
					disabled={ allDisabled }
					name={ `codeRateField.${ codeRateId }.employeeRate` }
					onBlur={ (event) => {
						handleRateBlur(event, newEmployerRate, setNewEmployeeRate, setTotal);
					} }
					onChange={ (event) => {
						handleRateChange(event, setNewEmployeeRate);
					} }
					placeholder="$"
					status={ getFieldClasses(hasError, newEmployeeRate) ? 'error' : undefined}
					value={ newEmployeeRate }
					width={ 4 }
				/>
			</span>
			<span className="CodeRateRow__formField">
				=
			</span>
			<span className="CodeRateRow__formField CodeRateRow__field">
				<TextField disabled={ true } value={ total } width={4} />
			</span>
			<Flex>
				<Label className="CodeRateRow__perHourMessage">
					{ $.__('per hour') }
				</Label>
			</Flex>
			{ifFeature(
				'encore',
				showRemoveBtn && (
				<IconButton
					floatingIcon={true}
					icon={
						<IconV2 color="neutral-medium" name="circle-x-solid" size={12} />
					}
					onClick={() => onRemoveClick(codeRateId)}
					size="teenie"
				/>),
			)}
			{ isCodesRatesEdit && history && (
				<div className="CodeRateRow__history">
					<div className={ historyDividerClasses }></div>
					{ ifFeature('encore', 
						<TextButton
							onClick={ () => setModalOpen(true) }
							startIcon={ <IconV2 name="clock-rotate-left-solid" size={12} /> }
							type="button"
						>
							{ $.__('Rate History') }
						</TextButton>,
						<TextButton
							clickAction={ () => setModalOpen(true) }
							iconBefore={ <ClockReverse14x13/> }
							text={ $.__('Rate History') }
							type="button"
						/>
					)}
					<RateHistoryModal
						code={ code }
						description={ description }
						handleClose={ () => setModalOpen(false) }
						isOpen={ modalOpen }
						rowData={ history }
					/>
					{ futureChange && (
						<Tooltip settings={ getTooltipSettings(futureChange) }>
							{ ifFeature('encore', <Flex marginLeft={ 1 }><IconV2 color="warning-medium" name="triangle-exclamation-solid" size={ 13 }/></Flex>, <span className="CodeRateRow__futureChange">
								<TriangleExclamation13x11/>
							</span>) }
						</Tooltip>
					) }
				</div>
			) }
		</div>
	);
}
