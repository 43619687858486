import classNames from 'classnames';

/**
 * Get the class names for the field inputs
 * @param hasError       {boolean}     Whether an error was detected when saving
 * @param value          {string}      The value of the field
 * @param defaultClasses {string|null} Other classes that are applicable to the field
 * @return {string}                    The classes that will be applied to the input
 */
export function getFieldClasses(hasError, value, defaultClasses = '') {
	const errorValue = value === '' || value === '$';
	return classNames(
		defaultClasses,
		{ 'fab-TextInput--error': hasError && errorValue }
	);
}

/**
 * Limit the input to a certain character count
 * @param value    {string}   The new value entered by the user
 * @param max      {number}   The maximum number of characters allowed
 * @param setValue {function} Function to set the value to state in the component
 */
export function handleFieldWithMaxChange(value, max, setValue) {
	if (value.length <= max) {
		setValue(value);
	}
}

/**
 * Handles the onBlur event for the rate fields
 * @param {React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>} event blur event
 * @param {string} otherRateValue value of the other rate field for totalling
 * @param {function} setRate callback to set the rate value
 */
export function handleRateBlur(event, otherRateValue, setRate, setTotal) {
	const { value } = event.target;

	if (!value) {
		return;
	}

	const formattedValue = formatAmount(value);

	setRate(formattedValue);
	setTotal(getTotalRateString(formattedValue, otherRateValue));
}

/**
 * Handles the onChange event for the rate fields
 * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event change event
 * @param {function} setRate callback to set the rate value
 */
export function handleRateChange(event, setRate) {
	const {value} = event.target;
	const isValid = validateCurrencyInput(value);

	if (isValid) {
		setRate(value);
	}
}

/**
 * Validates against a regex that checks for:
 * - an optional dollar sign ($),
 * - followed by up to 4 numbers and/or decimal (e.g. $.125)
 * - and then with up to 6 decimal places
 * @param {string} value
 * @returns {boolean}
 */
export function validateCurrencyInput(value) {
	// we may want to limit the decimals to 10 digits instead to accommodate for copy+paste
	// more digits and leave any necessary reformatting to another function.
	// This regex is explained in the JSDoc above.
	return /^\$?(\d{0,4}?(\.\d{0,6})?)?$/.test(value);
}

/**
 * Add both dollar amounts and format the total in a string
 * @param employerRate {string} Dollar amount
 * @param employeeRate {string} Dollar amount
 * @return             {string} Formatted total dollar amount
 */
export function getTotalRateString(employerRate, employeeRate) {
	const employerRateFloat = window.parseFloat(stripPunctuation(employerRate));
	const employeeRateFloat = window.parseFloat(stripPunctuation(employeeRate));
	const total = (employerRateFloat || 0) + (employeeRateFloat || 0);

	return formatAmount(total);
}

/**
 * Return a string with $ and , characters stripped out
 * @param dollarAmount {string} String that may contain $ and/or , characters
 * @return             {string} String with those characters stripped out
 */
export function stripPunctuation(dollarAmount) {
	return String(dollarAmount).replace('$', '').replace(',', '');
}

/**
 * Ensure that cents are formatted correctly, limited to 6 decimal places
 * and any needed zeroes are added and excessive zeroes are removed
 * and a '$' is added.
 * @param amount {string}
 * @return       {string}
 */
export function formatAmount(amount) {
	let newAmount = stripPunctuation(amount);

	// Catch any non numbers that sneak through, however
	// '.' should be the only non-number that can get through
	if (window.isNaN(newAmount)) {
		return `$0.00`;
	}

	if (typeof newAmount !== 'number') {
		newAmount = Number(newAmount);
	}

	// Round to 6 decimal places
	newAmount = Number(newAmount.toFixed(6));

	const newAmountDec = String(newAmount).split('.')[1];
	const newAmountDecLength = newAmountDec && newAmountDec.length > 2 ? newAmountDec.length : 2;

	return `$${ newAmount.toFixed(newAmountDecLength) }`;
}

/**
 * Create the settings object for tooltip
 * @param date {string} The date of the future change
 * @return     {object}
 */
export function getTooltipSettings(date) {
	return {
		template: {
			name: 'tooltip-standard',
			data: {
				content: $.__('Future change on %1$s', date),
			},
		},
	};
}
