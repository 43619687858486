import { Fragment } from 'react';

import { CompanyInfoHeader } from '../../../components/company-info-header';
import { CompanyInfoForm } from '../../../components/company-info-form';
import { InfoCardManager } from '../../../components/info-card-manager';
import { StatusRow } from '../../../components/status-row';

import { COMPANY_APP_STATE } from '../../../utils/shared';

export function CompanySavedStateComponent(props) {
	const {
		onInputChange,
		stateList,
		onSubmit,
		onFileDownload,
		contacts,
		company,
		statuses,
		isProcessing,
		revokePreviousContactPermission,
		onRevokePreviousContactPermission,
	} = props;
	return (
		<Fragment>
			<CompanyInfoHeader anotherEinLink={true} hasStatus={true} />

			<InfoCardManager
				clientId={company.clientId}
				company={company}
				isProcessing={isProcessing}
				onFileDownload={onFileDownload}
				statuses={[statuses]}
			/>

			<StatusRow status={COMPANY_APP_STATE.COMPANY_SAVED} />

			<CompanyInfoForm
				contactList={contacts}
				data={company}
				isProcessing={isProcessing}
				onInputChange={onInputChange}
				onSubmit={onSubmit}
				stateList={stateList}
				revokePreviousContactPermission={revokePreviousContactPermission}
				onRevokePreviousContactPermission={onRevokePreviousContactPermission}
			/>
		</Fragment>
	);
}
