import { Fragment, useState } from 'react';

import { BankAccountForm } from '../../../components/bank-account-form';
import { BankInfoHeader } from '../../../components/bank-info-header';

import { InfoCardManager } from '../../../../company-information.react/components/info-card-manager';
import { StatusRow } from '../../../../company-information.react/components/status-row';

import { isEnabled } from 'FeatureToggle.util';
import { UploadFormsModal } from '../../../../company-information.react/components/upload-forms-modal';
import { uuidGenerator } from '../../../../company-information.react/utils/shared';
import { AccountVerificationModal } from '../../../components/account-verification-modal/account-verification-modal';
import { MultiBanksTable } from '../../../components/multi-banks-table';
import { BANK_APP_STATE } from '../../../utils/shared';
import { LayoutBox } from '@bamboohr/fabric';

export function BankSavedStateComponent(props) {
	const {
		company,
		onSubmit,
		isProcessing,
		onInputChange,
		onFileDownload,
		plaidLinkToken,
		handlePlaidUpdateSuccess,
		onUploadForms,
		onVerificationModalSubmit,
	} = props;

	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [isVerificationsModalOpen, setIsVerificationsModalOpen] =
		useState(false);
	const [selectedBank, setSelectedBank] = useState({});
	const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);

	const popDownloadFormsModal = (data) => {
		setSelectedBank(data);
		setIsDownloadModalOpen(true);
	};
	const popUploadFormsModal = (data) => {
		setSelectedBank(data);
		setIsUploadModalOpen(true);
	};
	const popVerificationsModal = (data) => {
		setSelectedBank(data);
		setIsVerificationsModalOpen(true);
	};

	const openUploadModal = () => {
		setSelectedBank(company?.bankAccounts[0]);
		setIsUploadModalOpen(true);
	};
	const closeUploadModal = () => {
		setIsUploadModalOpen(false);
	};
	const closeVerificationsModal = () => {
		setIsVerificationsModalOpen(false);
	};

	const handleVerificationsModalSubmit = () => {
		setIsVerificationsModalOpen(false);
		onVerificationModalSubmit();
	};

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			<InfoCardManager
				buttonFunction={openUploadModal}
				clientId={company.clientId}
				company={company}
				isProcessing={isProcessing}
				onFileDownload={onFileDownload}
				statuses={[BANK_APP_STATE.BANK_SAVED]}
				plaidLinkToken={plaidLinkToken}
				handleLinkSuccess={handlePlaidUpdateSuccess}
				handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
			/>

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<LayoutBox height="24px" />
			) : (
				<StatusRow status={BANK_APP_STATE.BANK_SAVED} />
			)}
			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<MultiBanksTable
					company={company}
					handleOpenDownloadModal={popDownloadFormsModal}
					handleOpenUploadModal={popUploadFormsModal}
					handleOpenVerificationModal={popVerificationsModal}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
					plaidLinkToken={plaidLinkToken}
				/>
			) : (
				<BankAccountForm
					bankAccount={company.bankAccounts[0]}
					company={company}
					isEdit={true}
					isProcessing={isProcessing}
					onInputChange={onInputChange}
					onSubmit={onSubmit}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
				/>
			)}

			<UploadFormsModal
				bank={selectedBank}
				clientId={company.clientId}
				company={selectedBank ? selectedBank : {}}
				key={uuidGenerator()}
				onClose={closeUploadModal}
				onSubmit={onUploadForms}
				showDownloads={true}
				type="bank"
				visible={isUploadModalOpen}
			/>

			<AccountVerificationModal
				accountNum={selectedBank.accountNumber}
				bankId={selectedBank.bankId}
				bankName={selectedBank.accountNickname}
				clientId={company.clientId}
				isOpen={isVerificationsModalOpen}
				key={uuidGenerator()}
				onClose={closeVerificationsModal}
				onSubmit={handleVerificationsModalSubmit}
			/>
		</Fragment>
	);
}
