import './job-title-eeo-categories.styl';

import { Button } from '@fabric/button';

import { EEO1CategoriesModal } from 'eeo-1.mod';
import { fetchCategoryModalData } from './job-title-eeo-categories.service';
import { useState } from 'react';

export function JobTitleEEOCategories(props) {
	const { canEditEeo, isUsCompanyOnTitlePage, showEditButton } = props;
	const [data, setData] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	if (!canEditEeo || !isUsCompanyOnTitlePage) {
		return null;
	}

	return (
		<div className='JobTitleEEOCategories'>
			{showEditButton ? (
				<Button
					clickAction={() => _handleShowModal(setData, setIsOpen)}
					outline='true'
					secondary='true'
					size='teenie'
					text={$.__('Edit EEO Categories')}
					type='button'
					variant='outlined'
				/>
			) : (
					<Button
						clickAction={() => _handleShowModal(setData, setIsOpen)}
						text={$.__('Assign EEO Categories')}
						variant='outlined'
						size='teenie'
					/>
			)}

			<EEO1CategoriesModal
				canAutoOpen={false}
				data={data}
				isOpen={isOpen}
				isSettingsPage={true}
				onClose={() => setIsOpen(false)}
				onCloseAfterSave={() => window.location.reload()}
			/>
		</div>
	);
}

function _handleShowModal(setData, setIsOpen) {
	fetchCategoryModalData().then(
		(response) => {
			setData(response.data.data);
			setIsOpen(true);
		},
		(error) => {
			window.setMessage(error, 'info');
			console.error(error);

			return Promise.reject('Failed to get EEO Categories Modal data');
		}
	);
}
