import {
	BodyText,
	Flex,
	IconButton,
	IconV2,
	Pill,
	TextButton,
	Tooltip,
} from '@bamboohr/fabric';
import { canUseLocalStorage } from '@utils/localstorage';
import { useEffect, useRef, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import {
	BANK_APP_STATE,
	getAppStateFromData,
	nullBankAccount,
	showPlaidFailedMessage,
	takeToMultiEinEditBankPage,
} from '../../utils/shared';
import { PlaidOrManualDropdown } from '../plaid-or-manual-dropdown';

export function getColumns(
	handleOpenDownloadModal,
	handleOpenUploadModal,
	handleOpenVerificationModal,
	plaidLinkToken,
	handleLinkSuccess,
	isPlaidAdoptionAndSufficientBalanceEnabled,
	handlePlaidUpdateSuccess,
) {
	const HEADERS = [
		{
			header: $.__('Account Nickname/Type'),
			cell: (row) =>
				renderAccountNickname(
					row,
					plaidLinkToken,
					handleLinkSuccess,
					showPlaidFailedMessage,
					isPlaidAdoptionAndSufficientBalanceEnabled,
				),
		},
		{
			header: $.__('Account Info'),
			cell: (row) => {
				return row.isAddNew ? null : (
					<AccountDetails
						primaryText={`Account #: ${row.accountNumber}`}
						secondaryText={`Routing #: ${row.routingNumber}`}
					/>
				);
			},
		},
		{
			header: (
				<Tooltip
					content={$.__(
						'This includes a penny test, and giving BambooHR authorization to debit from your account.',
					)}
					placement="top"
				>
					<Flex alignItems="center" flexDirection="row" gap="4px">
						{$.__('Status')}

						<IconV2 name="circle-question-regular" size={12} />
					</Flex>
				</Tooltip>
			),
			cell: (row) =>
				renderStatus(
					row,
					isPlaidAdoptionAndSufficientBalanceEnabled,
					handleOpenVerificationModal,
					handleOpenUploadModal,
					handleOpenDownloadModal,
				),
		},
		{
			header: (
				<Tooltip
					content={$.__(
						'This is used to check account balance and ensure your team is paid on time.',
					)}
					placement="top"
				>
					<Flex alignItems="center" flexDirection="row" gap="4px">
						Plaid
						<IconV2 name="circle-question-regular" size={12} />
					</Flex>
				</Tooltip>
			),
			cell: (row) =>
				plaidRenderStatus(
					row,
					plaidLinkToken,
					handleLinkSuccess,
					handlePlaidUpdateSuccess,
				),
			align: 'center',
		},
	];

	const LEGACY_HEADERS = [
		{
			header: $.__('Account Nickname'),
			cell: (row) =>
				renderAccountNickname(
					row,
					plaidLinkToken,
					handleLinkSuccess,
					undefined,
					undefined,
					isPlaidAdoptionAndSufficientBalanceEnabled,
				),
		},
		{
			header: $.__('Account #'),
			cell: (row) => <div>{row.accountNumber}</div>,
		},
		{
			header: $.__('Routing #'),
			cell: (row) => <div>{row.routingNumber}</div>,
		},
		{
			header: $.__('Account Type'),
			cell: (row) => <div>{row.accountType}</div>,
		},
		{
			header: $.__('Status'),
			cell: (row) =>
				legacyRenderStatus(
					row,
					isPlaidAdoptionAndSufficientBalanceEnabled,
					handleOpenUploadModal,
					handleOpenDownloadModal,
					handleOpenVerificationModal,
				),
		},
	];
	return isPlaidAdoptionAndSufficientBalanceEnabled ? HEADERS : LEGACY_HEADERS;
}

export function getGroupsAndRows(companies) {
	const groups = [];
	const rows = [];

	companies.forEach((company) => {
		const { clientId, companyLegalName, ein, bankAccounts } = company;

		let truncatedCompanyName = companyLegalName;
		if (truncatedCompanyName.length > 24) {
			truncatedCompanyName = `${truncatedCompanyName.substring(0, 24)}...`;
		}

		// Add EIN as a row group
		groups.push({
			id: clientId,
			paddingReset: 'vertical',
			content: (
				<span className="MultiEinBanksTable__group">
					{truncatedCompanyName}
					<span className="MultiEinBanksTable__companyEin"> - {ein}</span>
				</span>
			),
		});

		if (bankAccounts.length) {
			// Add each bank account as an individual row
			bankAccounts.forEach((account) => {
				account.group = clientId;
				account.isAddNew = false;
				rows.push(account);
			});
		} else {
			rows.push({
				clientId,
				group: clientId,
				accountNickname: `+ ${$.__('Add Bank Account')}`,
				accountNumber: null,
				routingNumber: null,
				accountType: null,
				accountStatus: null,
				isAddNew: true,
			});
		}
	});

	// return both, in order to only loop through data once
	return {
		groups,
		rows,
	};
}

function renderAccountNickname(
	rowData,
	plaidLinkToken,
	handleLinkSuccess,
	showPlaidFailedMessage,
	isPlaidAdoptionAndSufficientBalanceEnabled,
) {
	const { clientId, bankId, accountNickname, isAddNew, bankName } = rowData;

	const clickManualAdd = () => {
		window.location.assign(
			`/settings/payroll/bank_account/multi_ein/${clientId}`,
		);
	};

	if (isAddNew) {
		if (plaidLinkToken) {
			return (
				<PlaidOrManualDropdown
					clickManualAdd={clickManualAdd}
					isAddAccount={true}
					onError={showPlaidFailedMessage}
					onSuccess={(token, metadata) => {
						handleLinkSuccess(token, metadata, clientId);
					}}
					plaidLinkToken={plaidLinkToken}
				/>
			);
		}

		const name = accountNickname ? accountNickname : bankName;

		return isPlaidAdoptionAndSufficientBalanceEnabled ? (
			<AccountDetails
				bankId={bankId}
				clientId={clientId}
				primaryText={name}
				secondaryText={rowData.accountType}
				takeToMultiEinEditBankPage={takeToMultiEinEditBankPage}
			/>
		) : (
			<a
				className="MultiEinBanksTable__addNew"
				href={`/settings/payroll/bank_account/multi_ein/${clientId}`}
			>
				{accountNickname}
			</a>
		);
	}
	return (
		<a
			className="MultiEinBanksTable__bankName"
			href={`/settings/payroll/bank_account/multi_ein/${clientId}/${bankId}`}
		>
			{accountNickname || bankName}
		</a>
	);
}

function legacyRenderStatus(
	rowData,
	isPlaidAdoptionAndSufficientBalanceEnabled,
	handleOpenUploadModal,
	handleOpenDownloadModal,
	handleOpenVerificationModal,
) {
	if (rowData.isAddNew) {
		return null;
	}

	if (rowData.penniesHasBeenStored && !rowData.isVerified) {
		return statusVerifingManualMode(isPlaidAdoptionAndSufficientBalanceEnabled);
	}

	if (rowData.penniesHasBeenStored && rowData.isVerified) {
		return statusVerified(rowData, isPlaidAdoptionAndSufficientBalanceEnabled);
	}

	const company = [{ bankAccounts: [] }];
	company[0].bankAccounts.push(rowData);

	const state = rowData.recordId
		? BANK_APP_STATE.PRE_EIN
		: getAppStateFromData(company);

	switch (state) {
		case BANK_APP_STATE.PRE_EIN:
			return null;

		case BANK_APP_STATE.BANK_SAVED:
			return statusCompanySaved(
				rowData,
				isPlaidAdoptionAndSufficientBalanceEnabled,
				handleOpenDownloadModal,
			);

		case BANK_APP_STATE.DOCS_DOWNLOADED:
			return statusDocsDownloaded(
				rowData,
				isPlaidAdoptionAndSufficientBalanceEnabled,
				handleOpenUploadModal,
			);

		case BANK_APP_STATE.DOCS_UPLOADED:
			return statusDocsUploaded(
				rowData,
				isPlaidAdoptionAndSufficientBalanceEnabled,
				handleOpenVerificationModal,
			);

		case BANK_APP_STATE.VERIFIED:
			return statusVerified(
				rowData,
				isPlaidAdoptionAndSufficientBalanceEnabled,
			);

		default:
			break;
	}
}

const statusCompanySaved = (
	row,
	isPlaidAdoptionAndSufficientBalanceEnabled,
	handleOpenDownloadModal,
) => {
	const tempText = $.__('Download Forms');

	return (
		<Flex alignItems="flex-start" flexDirection="column">
			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<Pill icon="hourglass-solid" type="Neutral">
					{$.__('Pending')}
				</Pill>
			) : (
				<Flex alignItems="center" gap={0.75}>
					<IconV2 color="neutral-medium" name="hourglass-solid" size={16} />
					<BodyText color="neutral-medium">{$.__('Pending')}</BodyText>
				</Flex>
			)}
			<TextButton
				inline={true}
				onClick={() => handleOpenDownloadModal(row)}
				type="button"
			>
				{tempText}
			</TextButton>
		</Flex>
	);
};

const statusDocsUploaded = (
	row,
	isPlaidAdoptionAndSufficientBalanceEnabled,
	handleOpenVerificationModal,
) => {
	return (
		<Flex alignItems="flex-start" flexDirection="column">
			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<Pill icon="hourglass-solid" type="Neutral">
					{$.__('Pending')}
				</Pill>
			) : (
				<Flex alignItems="center" gap={0.75}>
					<IconV2 color="neutral-medium" name="hourglass-solid" size={16} />
					<BodyText color="neutral-medium">{$.__('Pending')}</BodyText>
				</Flex>
			)}
			{!isPlaidAdoptionAndSufficientBalanceEnabled && (
				<TextButton
					inline={true}
					onClick={() => handleOpenVerificationModal(row)}
					type="button"
				>
					{$.__('Verify Account')}
				</TextButton>
			)}
		</Flex>
	);
};

const statusVerified = (row, isPlaidAdoptionAndSufficientBalanceEnabled) => {
	return (
		<>
			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<Flex alignItems="center" gap={0.75}>
					<Pill icon="check-solid" type="Brand">
						{$.__('Verified')}
					</Pill>
				</Flex>
			) : (
				<Flex alignItems="center" gap={0.75}>
					<IconV2 color="success-strong" name="circle-check-solid" size={16} />
					<BodyText color="success-strong">{$.__('Verified')}</BodyText>
				</Flex>
			)}
		</>
	);
};

export const plaidConnectionVerified = (row) => {
	return (
		<Flex alignItems="center" justifyContent="center">
			<IconV2 color="success-strong" name="plug-circle-check-solid" size={16} />
		</Flex>
	);
};

export const AccountDetails = ({
	primaryText,
	secondaryText,
	takeToMultiEinEditBankPage = () => {},
	bankId,
	clientId,
}) => {
	return (
		<Flex
			alignItems="flex-start"
			flexDirection="column"
			justifyContent="flex-start"
		>
			{bankId ? (
				<TextButton
					alignContent="left"
					onClick={() => takeToMultiEinEditBankPage(clientId, bankId)}
					size="medium"
					style={{
						height: 'unset',
					}}
				>
					{primaryText}
				</TextButton>
			) : (
				<BodyText color="neutral-extra-strong" size="medium">
					{primaryText}
				</BodyText>
			)}

			<BodyText color="neutral-weak" size="medium" weight="regular">
				{secondaryText}
			</BodyText>
		</Flex>
	);
};

export const plaidRenderStatus = (
	row,
	plaidLinkToken,
	handleLinkSuccess,
	handlePlaidUpdateSuccess,
) => {
	const company = [{ bankAccounts: [] }];
	company[0].bankAccounts.push({ ...nullBankAccount, ...row });
	if (row.isAddNew) {
		return null;
	}
	if (row.plaidStatus === 'Linked') {
		return plaidConnectionVerified(row);
	}
	if (row.plaidStatus === 'Failed') {
		return plaidReconnect(row);
	}
	return (
		<PlaidGetStarted
			handleLinkSuccess={handleLinkSuccess}
			handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
			plaidLinkToken={plaidLinkToken}
			row={row}
		/>
	);
};

const statusDocsDownloaded = (
	row,
	isPlaidAdoptionAndSufficientBalanceEnabled,
	handleOpenUploadModal,
) => {
	const tempText = $.__('Upload Signed Forms');

	return (
		<Flex alignItems="flex-start" flexDirection="column">
			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<Pill icon="hourglass-solid" type="Neutral">
					{$.__('Pending')}
				</Pill>
			) : (
				<Flex alignItems="center" gap={0.75}>
					<IconV2 color="neutral-medium" name="hourglass-solid" size={16} />
					<BodyText color="neutral-medium">{$.__('Pending')}</BodyText>
				</Flex>
			)}
			{!isPlaidAdoptionAndSufficientBalanceEnabled && (
				<TextButton
					inline={true}
					onClick={() => handleOpenUploadModal(row)}
					type="button"
				>
					{tempText}
				</TextButton>
			)}
		</Flex>
	);
};

const statusVerifingManualMode = (
	isPlaidAdoptionAndSufficientBalanceEnabled,
) => {
	return (
		<Flex alignItems="flex-start" flexDirection="column">
			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<Pill icon="hourglass-solid" type="Neutral">
					{$.__('Verifying Amount')}
				</Pill>
			) : (
				<Flex alignItems="center" gap={0.75}>
					<IconV2 color="neutral-medium" name="hourglass-solid" size={16} />
					<BodyText color="neutral-medium">{$.__('Pending')}</BodyText>
				</Flex>
			)}
			{isPlaidAdoptionAndSufficientBalanceEnabled ? null : (
				<BodyText color="neutral-weak">
					{$.__('Verifying deposit amount')}
				</BodyText>
			)}
		</Flex>
	);
};

export const renderStatus = (
	row,
	isPlaidAdoptionAndSufficientBalanceEnabled,
	handleOpenVerificationModal,
	handleOpenUploadModal,
	handleOpenDownloadModal,
) => {
	const company = [{ bankAccounts: [] }];
	company[0].bankAccounts.push({ ...nullBankAccount, ...row });

	if (row.penniesHasBeenStored && !row.isVerified) {
		return statusVerifingManualMode(isPlaidAdoptionAndSufficientBalanceEnabled);
	}

	if (row.penniesHasBeenStored && row.isVerified) {
		return statusVerified(row, isPlaidAdoptionAndSufficientBalanceEnabled);
	}

	const state = row.recordId
		? BANK_APP_STATE.PRE_EIN
		: getAppStateFromData(company);

	switch (state) {
		case BANK_APP_STATE.PRE_EIN:
			return null;

		case BANK_APP_STATE.BANK_SAVED:
			return statusBankSaved(
				row,
				isPlaidAdoptionAndSufficientBalanceEnabled,
				handleOpenDownloadModal,
			);

		case BANK_APP_STATE.DOCS_DOWNLOADED:
			return statusDocsDownloaded(
				row,
				isPlaidAdoptionAndSufficientBalanceEnabled,
			);

		case BANK_APP_STATE.DOCS_UPLOADED:
			return statusDocsUploaded(
				row,
				isPlaidAdoptionAndSufficientBalanceEnabled,
			);

		case BANK_APP_STATE.VERIFIED:
			return statusVerified(row, isPlaidAdoptionAndSufficientBalanceEnabled);

		default:
			break;
	}
};

export const plaidReconnect = (row) => {
	return (
		<Flex alignItems="center" justifyContent="center">
			<IconV2
				color="warning-strong"
				name="triangle-exclamation-solid"
				size={16}
			/>
		</Flex>
	);
};
export const PlaidGetStarted = ({
	row,
	plaidLinkToken,
	handleLinkSuccess,
	handlePlaidUpdateSuccess,
}) => {
	const [isActionable, setIsActionable] = useState(false);
	useEffect(() => {
		document.addEventListener(
			'PlaidBannerActions:Dismissed',
			handlePlaidBannerDismissed,
		);
		if (canUseLocalStorage) {
			const val = localStorage.getItem(
				`_plaid_messaging_${row.accountNickname + row.bankId}_`,
			);
			if (!val) {
				setIsActionable(false);
			} else {
				setIsActionable(true);
			}
		}
	}, []);
	function handlePlaidBannerDismissed(e) {
		const eventBankId = e.detail;
		if (eventBankId === row.bankId) {
			setIsActionable(true);
		}
	}

	return (
		<Flex alignItems="center" justifyContent="center">
			{isActionable ? (
				<PlaidLinkButton
					icon="plug-circle-plus-regular"
					onSuccess={(token, metadata) => {
						if (row?.accountNumber) {
							handlePlaidUpdateSuccess(
								token,
								metadata,
								row.bankId,
								row.clientId,
							);
						} else {
							handleLinkSuccess(token, metadata, row.clientId);
						}
					}}
					plaidLinkToken={plaidLinkToken}
				/>
			) : (
				<IconV2
					color="neutral-strong"
					name="plug-circle-plus-regular"
					size={16}
				/>
			)}
		</Flex>
	);
};

const PlaidLinkButton = ({ plaidLinkToken, onSuccess, icon, color }) => {
	const buttonEl = useRef(null);
	const { open, ready, error } = usePlaidLink({
		token: plaidLinkToken,
		onSuccess,
	});

	if (error) {
		showPlaidFailedMessage();
	}

	return (
		<Tooltip
			content={$.__(
				'Connect this bank account with Plaid to ensure faster payroll processing.',
			)}
			placement="bottom-start"
		>
			<IconButton
				active={false}
				color={color}
				floatingIcon={true}
				icon={icon}
				onClick={() => {
					if (ready) {
						open();
					}
				}}
				processing={!ready}
				ref={buttonEl}
				size="small"
			/>
		</Tooltip>
	);
};

const statusBankSaved = (
	row,
	isPlaidAdoptionAndSufficientBalanceEnabled,
	handleOpenDownloadModal,
) => {
	const tempText = $.__('Download Forms');

	return isPlaidAdoptionAndSufficientBalanceEnabled ? (
		<>
			<Flex alignItems="center" gap={0.75}>
				<Pill icon="hourglass-solid" type="Inactive">
					{$.__('Pending')}
				</Pill>
			</Flex>
		</>
	) : (
		<Flex alignItems="flex-start" flexDirection="column">
			<Flex alignItems="center" gap={0.75}>
				<IconV2 color="neutral-medium" name="hourglass-solid" size={16} />
				<BodyText color="neutral-medium">{$.__('Pending')}</BodyText>
			</Flex>
			<TextButton
				inline={true}
				onClick={() => handleOpenDownloadModal(row)}
				type="button"
			>
				{tempText}
			</TextButton>
		</Flex>
	);
};
