import React from 'react';

import { GreenhouseIcon } from './icons';
import { MicroFrontend } from './micro-frontend';
import { useResource } from './resources';
import { VersionManager } from './version-manager';

export const DeveloperPortalMicroFrontend = () => {
	const name = 'developerportal';
	const [resource, setResource] = useResource(name);

	const payload = {
		globals: { environment: window.ENV },
	};

	return (
		// Todo change icon to developers-portal
		<VersionManager
			icon={<GreenhouseIcon />}
			initialResource={resource}
			onChange={setResource}
		>
			<MicroFrontend
				host={resource?.url}
				manifest="manifest.json"
				name={name}
				payload={payload}
			/>
		</VersionManager>
	);
};
