import { isEnabled } from '@bamboohr/utils/lib/feature';

export const PERM_LEVEL = {
	NONE: 0,
	VIEW: 1,
	VIEW_REQUEST: 2,
	VIEW_EDIT: 3,
};

const stripSymbolsFromAmount = amount => amount.replace('%', '').replace(',', '').replace('$', '');

export const togglePaperCheckMsg = account => account.calculationType === 'Balance' && Number(stripSymbolsFromAmount(account.amount)) === 100;

export function getIsPayrollDirectDepositUpdateUxEnabled() {
	return isEnabled('PAYROLL_DIRECT_DEPOSIT_UPDATE_UX');
}