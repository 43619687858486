import {
	Button,
	Checkbox,
	Flex,
	makeStyles,
	StandardModal,
	TextButton,
} from '@bamboohr/fabric';
import classnames from 'classnames';
import { startCase } from 'lodash';
import { useValidation } from '@utils/validation';
import { useState } from 'react';

import { showSlidedown } from 'Alerts.mod';
import config from 'micro-frontend.config';

import { checkUrlExists } from './etc';

import { OdiVersionPicker } from './odi-version-picker';
import { ProductionVersionPicker } from './production-version-picker';
import { ResourceTypePicker } from './resource-type-picker';
import { useOdiResources } from '../hooks/use-odi-resources';

const useStyles = makeStyles(({ palette, typography }) => ({
	root: {
		display: 'grid',
		gap: 15,
		gridColumnTemplate: '1fr',
		marginTop: 10,
	},
	label: {
		color: palette.grey[800],
		display: 'block',
		fontSize: typography.fabricFontSize('large'),
	},
	infoText: {
		color: palette.grey[800],
		fontSize: typography.fabricFontSize('small'),
		marginTop: 5,
	},
	resourceTypePicker: {
		marginBottom: 10,
	},
}));

export const ResourcePickerModal = ({
	getUrl,
	isOpen,
	onCancel,
	onSave,
	value,
}) => {
	const classes = useStyles();

	const { errors, register, validate } = useValidation();

	const [odiResources, isLoading] = useOdiResources(value?.name);

	const [draftType, setDraftType] = useState();
	const [draftVersion, setDraftVersion] = useState();
	const [sessionOnly, setSessionOnly] = useState(value?.sessionOnly);

	const resetDrafts = () => {
		setDraftType(undefined);
		setDraftVersion(undefined);
	};

	const hasDrafts = draftType || draftVersion;

	const name = value?.name;
	const type = draftType || value?.type;
	const version =
		(draftType && draftType !== 'committed') || draftVersion
			? draftVersion
			: value?.version;
	const url = getUrl({ type, version });

	const showUrl =
		type === 'committed' ||
		type === 'development' ||
		(version && (type === 'custom' || type === 'odi'));

	const handleSaveClick = async () => {
		const isValid = validate();

		if (isValid) {
			const exists = await checkUrlExists(`${url}/manifest.json`);

			if (exists) {
				onSave({ ...value, type, version, url, sessionOnly });
				resetDrafts();
			} else {
				showSlidedown('The selected version does not exist.', 'error');
			}
		}
	};

	const handleCancelClick = () => {
		resetDrafts();
		onCancel();
	};

	return (
		<StandardModal
			isLoading={isLoading}
			isOpen={isOpen}
			onRequestClose={handleCancelClick}
		>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<Button
								ariaLabel="save version"
								disabled={!hasDrafts && sessionOnly === value.sessionOnly}
								key="saveButton"
								onClick={handleSaveClick}
							>
								Save
							</Button>,
							<TextButton
								ariaLabel="cancel version picker"
								key="cancelButton"
								onClick={handleCancelClick}
							>
								Cancel
							</TextButton>,
						]}
					>
						<Flex alignItems="end" justifyContent="end" width="100%">
							<Checkbox
								checked={sessionOnly}
								onChange={(param, event) => {
									setSessionOnly(event.target.checked);
								}}
								label="Save version for this tab only"
							/>
						</Flex>
					</StandardModal.Footer>
				}
				renderHeader={
					<StandardModal.Header
						title={`Change the version of ${startCase(name)}`}
					/>
				}
				size={StandardModal.Body.Size.MEDIUM}
			>
				<StandardModal.Constraint>
					<form className={classnames(classes.root)}>
						<span className={classes.label}>
							Which version of code should we use?
						</span>
						<div classes={{ root: classes.resourceTypePicker }}>
							<ResourceTypePicker
								disabledTypes={odiResources?.length ? ['development'] : ['odi']}
								onChange={(_value) => {
									setDraftType(_value);

									if (_value === 'committed') {
										setDraftVersion(config.versions[name]);
									} else {
										setDraftVersion(undefined);
									}
								}}
								value={type}
							/>
						</div>

						{type === 'odi' || type === 'custom' ? (
							<div>
								{type === 'odi' ? (
									<OdiVersionPicker
										ref={register({ required: true })}
										errors={errors}
										name={name}
										odiResources={odiResources}
										onChange={(_value) => setDraftVersion(_value)}
										value={version}
									/>
								) : null}

								{type === 'custom' ? (
									<ProductionVersionPicker
										ref={register({ required: true })}
										errors={errors}
										name={name}
										onChange={(_value) => setDraftVersion(_value)}
										value={version || ''}
									/>
								) : null}
							</div>
						) : null}

						{showUrl ? (
							<div className={classes.infoText}>
								Resources will be loaded from:&nbsp;
								<a href={url} rel="noopener noreferrer" target="_blank">
									{url}
								</a>
							</div>
						) : null}
					</form>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};
