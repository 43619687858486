import { Fragment } from 'react';
import { isEnabled } from 'FeatureToggle.util';

import { BankInfoHeader } from '../../../components/bank-info-header';
import { BankAccountForm } from '../../../components/bank-account-form';

import { StatusRow } from '../../../../company-information.react/components/status-row';

import { BANK_APP_STATE } from '../../../utils/shared';
import { MultiBanksTable } from '../../../components/multi-banks-table';
import { Button, InPageMessaging, LayoutBox } from '@bamboohr/fabric';
import { usePlaidLink } from 'react-plaid-link';

export function BankVerifiedStateComponent(props) {
	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);

	const {
		company,
		onSubmit,
		isProcessing,
		onInputChange,
		plaidLinkToken,
		handlePlaidUpdateSuccess,
		popDownloadFormsModal,
		popUploadFormsModal,
		popVerificationsModal,
	} = props;

	const bank = company.bankAccounts[0];

	const { open, ready } = usePlaidLink({
		token: plaidLinkToken,
		onSuccess: (token, metadata) => {
			handlePlaidUpdateSuccess(token, metadata, bank.bankId, bank.clientId);
		},
	});
	return (
		<Fragment>
			<BankInfoHeader />

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<LayoutBox
					marginTop="18px"
					marginBottom={bank.plaidStatus === 'Failed' ? '24px' : undefined}
				>
					{bank.plaidStatus === 'Failed' && (
						<InPageMessaging
							body={$.__(
								'Please connect again to continue smooth payroll processing.',
							)}
							customAction={
								<Button
									color="secondary"
									onClick={() => {
										if (ready) {
											open();
										}
									}}
									size="teenie"
									type="button"
									variant="outlined"
								>
									{$.__('Reconnect')}
								</Button>
							}
							header={$.__(
								'Your Plaid connection has expired for %1$s',
								bank.accountNickname,
							)}
							icon="triangle-exclamation-solid"
							type="warning"
						/>
					)}
				</LayoutBox>
			) : (
				<StatusRow
					status={BANK_APP_STATE.VERIFIED}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
				/>
			)}

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<MultiBanksTable
					company={company}
					handleOpenDownloadModal={popDownloadFormsModal}
					handleOpenUploadModal={popUploadFormsModal}
					handleOpenVerificationModal={popVerificationsModal}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
				/>
			) : (
				<BankAccountForm
					isEdit
					bankAccount={company.bankAccounts[0]}
					company={company}
					isProcessing={isProcessing}
					isVerified={true}
					onInputChange={onInputChange}
					onSubmit={onSubmit}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
					showCompletedFormsSection={!company.isLegacy}
					statusSpacing={true}
				/>
			)}
		</Fragment>
	);
}
