import {
	Avatar,
	BodyText,
	Flex,
	IconV2,
	LayoutBox,
	Link,
} from '@bamboohr/fabric';
import { ReactElement } from 'react';

import {
	Employee,
	SocialItem,
} from 'NHPTemplates.mod/Previewer/Components/EmployeeInfo/employeeInterface';

interface Props {
	employee: Employee;
	avatarSize?: 32 | 40 | 48 | 56 | 64 | 72 | 80 | 96 | 128 | 160 | 224;
}

const SOCIAL_ICONS = {
	Aol: <IconV2 name="globe-solid" size={20} />,
	Facebook: <IconV2 name="facebook" size={20} />,
	Instagram: <IconV2 name="square-instagram" size={20} />,
	LinkedIn: <IconV2 name="linkedin" size={20} />,
	Pinterest: <IconV2 name="pinterest-p" size={20} />,
	Skype: <IconV2 name="skype" size={20} />,
	Twitter: <IconV2 name="square-x-twitter" size={20} />,
};

export function EmployeeInfo(props: Props): ReactElement {
	const { employee, avatarSize } = props;
	const {
		email,
		extension,
		firstName,
		lastName,
		phone,
		position,
		profileImageSrc,
		pronounsLabel,
		socials,
	} = employee;
	const defaultAvatarSize = 64;
	const shouldSetMarginBottom =
		Array.isArray(socials) && !email && socials.length === 0;

	return (
		<Flex gap={2} marginBottom={shouldSetMarginBottom ? '20px' : undefined}>
			<Avatar
				alt={`${firstName} ${lastName}`}
				size={avatarSize || defaultAvatarSize}
				src={profileImageSrc}
			/>
			<LayoutBox>
				<BodyText color="neutral-extra-strong" weight="regular" size="medium">
					{firstName} {lastName}
					{pronounsLabel && (
						<BodyText
							color="neutral-extra-strong"
							inline={true}
							size="extra-small"
						>
							({pronounsLabel})
						</BodyText>
					)}
				</BodyText>
				<BodyText
					color="neutral-extra-strong"
					size="extra-small"
					weight="regular"
				>
					{position}
				</BodyText>
				<BodyText color="neutral-extra-strong" size="extra-small">
					{phone} {extension && <span>ext. {extension}</span>}
				</BodyText>
				{email && (
					<Link
						href={`mailto:${email}`}
						rel="noopener noreferrer"
						size="extra-small"
						target="_blank"
					>
						{email}
					</Link>
				)}
				{(email || socials) && (
					<Flex gap="10px" marginTop="10px">
						{email && (
							<Link
								color="muted"
								href={`mailto:${email}`}
								rel="noopener noreferrer"
								target="_blank"
							>
								<IconV2 name="envelope-solid" size={20} />
							</Link>
						)}
						{socials &&
							socials.map((socialItem: SocialItem) => {
								return (
									<Link
										color="muted"
										href={socialItem.href}
										key={socialItem.type}
										rel="noopener noreferrer"
										target="_blank"
									>
										{SOCIAL_ICONS[socialItem.type]}
									</Link>
								);
							})}
					</Flex>
				)}
			</LayoutBox>
		</Flex>
	);
}
