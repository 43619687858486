import { Fragment, useState } from 'react';
import { isEnabled } from 'FeatureToggle.util';

import { BankInfoHeader } from '../../../components/bank-info-header';
import { BankAccountForm } from '../../../components/bank-account-form';

import { StatusRow } from '../../../../company-information.react/components/status-row';
import { InfoCardManager } from '../../../../company-information.react/components/info-card-manager';

import { BANK_APP_STATE } from '../../../utils/shared';
import { uuidGenerator } from '../../../../company-information.react/utils/shared.js';
import { AccountVerificationModal } from '../../../components/account-verification-modal/account-verification-modal';
import { LayoutBox } from '@bamboohr/fabric';
import { MultiBanksTable } from '../../../components/multi-banks-table/multi-banks-table.js';

export function DocsUploadedStateComponent(props) {
	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);
	const {
		company,
		onSubmit,
		isProcessing,
		onInputChange,
		plaidLinkToken,
		handlePlaidUpdateSuccess,
		popDownloadFormsModal,
		popUploadFormsModal,
		popVerificationsModal,
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const status = {
		company,
		state: BANK_APP_STATE.DOCS_UPLOADED,
	};

	const handleVerificationsModalSubmit = () => {
		const { onVerificationModalSubmit } = props;

		onVerificationModalSubmit();
	};

	const hasPenniesBeenStored = () => {
		const { company } = props;

		return company.bankAccounts[0].penniesHasBeenStored;
	};

	const statusRowStatus = hasPenniesBeenStored()
		? BANK_APP_STATE.VERIFYING_MANUAL_MODE
		: BANK_APP_STATE.DOCS_UPLOADED;

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			<InfoCardManager
				buttonFunction={setIsModalOpen}
				clientId={company.clientId}
				company={company}
				isProcessing={isProcessing}
				onFileDownload={() => {}}
				statuses={[status]}
				plaidLinkToken={plaidLinkToken}
				handleLinkSuccess={handlePlaidUpdateSuccess}
				handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
			/>

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<LayoutBox height="24px" />
			) : (
				<StatusRow clickAction={setIsModalOpen} status={statusRowStatus} />
			)}

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<MultiBanksTable
					company={company}
					handleOpenDownloadModal={popDownloadFormsModal}
					handleOpenUploadModal={popUploadFormsModal}
					handleOpenVerificationModal={popVerificationsModal}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
				/>
			) : (
				<BankAccountForm
					isEdit
					bankAccount={company.bankAccounts[0]}
					company={company}
					isProcessing={isProcessing}
					isVerified={true}
					onInputChange={onInputChange}
					onSubmit={onSubmit}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
					showCompletedFormsSection={true}
				/>
			)}

			<AccountVerificationModal
				key={uuidGenerator()}
				accountNum={company.bankAccounts[0].accountNumber}
				bankId={company.bankAccounts[0].bankId}
				bankName={company.bankAccounts[0].accountNickname}
				clientId={company.clientId}
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
				onSubmit={handleVerificationsModalSubmit}
			/>
		</Fragment>
	);
}
