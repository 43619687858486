import { formatTime } from 'time-tracking/utils';
import { BodyText, Flex } from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* @endCleanup encore */

export default function SummaryBreakdown(props) {
	const { periodOvertime, periodTimeOff, periodHoliday, shiftDifferentials, holidaySummary } = props;
	const showMultiplier = periodOvertime.length > 1;

	return ifFeature(
		'encore',
		<Flex alignItems="center" flexDirection="column" gap={0.5}>
			{!!periodOvertime.length &&
				periodOvertime.map((item) => (
					<BodyText color="warning-strong" key={item.multiplier}>
						{formatTime(item.hours, true)} {$.__('Overtime')}{' '}
						{showMultiplier && `(${item.multiplier}x)`}
					</BodyText>
				))}

			{!!shiftDifferentials.length &&
				shiftDifferentials.map((item) => (
					<BodyText color="neutral-medium" key={item.id}>
						{formatTime(item.hours, true)} {item.name}
					</BodyText>
				))}

			{!!periodTimeOff && (
				<BodyText color="neutral-medium">
					{formatTime(periodTimeOff, true)} {$.__('Time Off')}
				</BodyText>
			)}

			{!!periodHoliday && ifFeature('HOLIDAY_PAY', null,
				<BodyText color="neutral-medium">
					{formatTime(periodHoliday, true)} {$.__('Holiday')}
				</BodyText>
			)}

			{ifFeature(
				'HOLIDAY_PAY',
				!!holidaySummary?.length && holidaySummary?.sort((a, b) => {
						// sort the array of holidays so that the "worked" holidays are at the bottom
						if (a.type < b.type) return -1;
						if (a.type > b.type) return 1;
						return 0;
					})?.map((holiday, key) => {
						if( holiday.type === 'paid' ){
							return (
								<BodyText color="neutral-medium" key={key}>
									{formatTime(holiday.hours, true)}
									{$.__(' Holiday')}
								</BodyText>
							);
						}
						if( holiday.type === 'worked' ){
							return (
								<BodyText color="neutral-medium" key={key}>
									{formatTime(holiday.hours, true)}
									{$.__(' Worked Holiday (pays %1x)', holiday.multiplier)}
								</BodyText>
							);
						}
						return null;
					}),
			)}
		</Flex>,
		<>
			{!!periodOvertime.length &&
				periodOvertime.map((item) => (
					<div
						className="TimesheetSummary__breakdownItem TimesheetSummary__breakdownItem--warning"
						key={item.multiplier}
					>
						{formatTime(item.hours, true)} {$.__('Overtime')}{' '}
						{showMultiplier && `(${item.multiplier}x)`}
					</div>
				))}

			{!!periodTimeOff && (
				<div className="TimesheetSummary__breakdownItem">
					{formatTime(periodTimeOff, true)} {$.__('Time Off')}
				</div>
			)}

			{!!periodHoliday && (
				<div className="TimesheetSummary__breakdownItem">
					{formatTime(periodHoliday, true)} {$.__('Holiday')}
				</div>
			)}
		</>,
	);
}
