import { Table } from '@bamboohr/fabric';
import { isEnabled } from 'FeatureToggle.util';

import './multi-ein-banks-table.styl';

import { getColumns, getGroupsAndRows } from './utils';

export function MultiEinBanksTable(props) {
	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);
	const {
		companies,
		handleOpenUploadModal,
		handleOpenDownloadModal,
		handleOpenVerificationModal,
		plaidLinkToken,
		handleLinkSuccess,
		handlePlaidUpdateSuccess,
	} = props;

	const { groups, rows } = getGroupsAndRows(companies);

	return (
		<div className="MultiEinBanksTable">
			<Table
				caption={$.__('Bank accounts for multiple EINs')}
				columns={getColumns(
					handleOpenDownloadModal,
					handleOpenUploadModal,
					handleOpenVerificationModal,
					plaidLinkToken,
					handleLinkSuccess,
					isPlaidAdoptionAndSufficientBalanceEnabled,
					handlePlaidUpdateSuccess,
				)}
				groups={groups}
				rowKey={(row) => row.bankId || row.clientId}
				rows={rows}
			/>
		</div>
	);
}
