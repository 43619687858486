import {
	Table,
	Flex,
	Tooltip,
	TextButton,
	IconV2,
	Link,
	LayoutBox,
	BodyText,
	Pill,
} from '@bamboohr/fabric';
import {
	CheckCircle14x14,
	Hourglass13x16,
	Question14x14,
} from '@bamboohr/grim';
import {
	COMPANY_APP_STATE,
	getMultiEinAddCompanyPageUrl,
	getMultiEinEditCompanyPageUrl,
	takeToBankAccountSection,
	getAppStateFromData,
	payrollIdExplanation,
} from '../../utils/shared';

import './companies-table.styl';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isEnabled } from 'FeatureToggle.util';

export function CompaniesTable(props) {
	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled('PLAID_ADOPTION_AND_SUFFICIENT_BALANCE');
	const COLUMNS = [
		{
			header: (
				<Flex alignItems="center" gap={0.75}>
					<span>{$.__('ID')}</span>
					<Tooltip content={payrollIdExplanation}>
						{ifFeature(
							'encore',
							<Flex>
								<IconV2
									name="circle-info-solid"
									color="neutral-medium"
									size={16}
								/>
							</Flex>,
							<Flex>
								<Question14x14 fill="#555" />
							</Flex>,
						)}
					</Tooltip>
				</Flex>
			),
			cell: (row) => row.clientId,
			key: 'companyId',
		},
		{
			header: $.__('Company Legal Name'),
			cell: (row) => (
				<LayoutBox whiteSpace="nowrap">{renderCompanyLegalName(row)}</LayoutBox>
			),
		},
		{
			header: $.__('Status'),
			cell: (row) => (
				<LayoutBox whiteSpace="nowrap">{renderStatus(row)}</LayoutBox>
			),
		},
		{
			header: $.__('DBA'),
			cell: (row) => renderDba(row),
		},
		{
			header: $.__('EIN'),
			cell: (row) => <div>{row.ein}</div>,
		},
		{
			header: $.__('Address'),
			cell: (row) => formatAddress(row),
			width: '200px',
		},
		{
			header: $.__('Company Phone Number'),
			cell: (row) => row.phoneNumber,
			width: '150px',
		},
		{
			header: $.__('Company Web Address'),
			cell: (row) => row.website || '',
			width: '150px',
		},
		{
			header: $.__('Business Type'),
			cell: (row) => row.businessType,
			width: '150px',
		},
	];

	const renderDba = (row) => {
		return <LayoutBox whiteSpace="nowrap">{row.dbaName}</LayoutBox>;
	};

	const renderCompanyLegalName = (row) => {
		if (row.isPotentialEin) {
			return (
				<Link
					href={getMultiEinAddCompanyPageUrl(row.recordId)}
				>{`+ Add ${row.companyLegalName}`}</Link>
			);
		}

		return (
			<Link href={getMultiEinEditCompanyPageUrl(row.clientId)}>
				{row.companyLegalName}
			</Link>
		);
	};

	const statusCompanySaved = (row) => {
		const { handleOpenDownloadModal } = props;
		return (
			<Flex flexDirection="column" alignItems="flex-start">
				{isPlaidAdoptionAndSufficientBalanceEnabled ? (
					<Pill icon="hourglass-solid" type="Inactive">
						{$.__('Pending')}
					</Pill>
				) : (
					<Flex gap={0.75} alignItems="center">
						{ifFeature(
							'encore',
							<IconV2 name="hourglass-solid" color="neutral-medium" size={16} />,
							<span className="CompaniesTable__statusPendingIcon">
								<Hourglass13x16 />
							</span>,
						)}
						<BodyText color="neutral-medium">{$.__('Pending')}</BodyText>
					</Flex>
				)}
				<TextButton
					onClick={() => handleOpenDownloadModal(row)}
					inline
					type="button"
				>
					{$.__('Download Forms')}
				</TextButton>
			</Flex>
		);
	};

	const statusDocsDownloaded = (row) => {
		const { handleOpenUploadModal } = props;

		return (
			<Flex flexDirection="column" alignItems="flex-start">
				{isPlaidAdoptionAndSufficientBalanceEnabled ? (
					<Pill icon="hourglass-solid" type="Inactive">
						{$.__('Pending')}
					</Pill>
				) : (
					<Flex gap={0.75} alignItems="center">
						{ifFeature(
							'encore',
							<IconV2 name="hourglass-solid" color="neutral-medium" size={16} />,
							<span className="CompaniesTable__statusPendingIcon">
								<Hourglass13x16 />
							</span>,
						)}
						<BodyText color="neutral-medium">{$.__('Pending')}</BodyText>
					</Flex>
				)}
				<TextButton
					onClick={() => handleOpenUploadModal(row)}
					inline
					type="button"
				>
					{$.__('Upload Signed Forms')}
				</TextButton>
			</Flex>
		);
	};

	const statusDocsUploaded = () => {
		return (
			<Flex flexDirection="column" alignItems="flex-start">
				{isPlaidAdoptionAndSufficientBalanceEnabled ? (
					<Pill icon="hourglass-solid" type="Inactive">
						{$.__('Pending')}
					</Pill>
				) : (
					<Flex gap={0.75} alignItems="center">
					{ifFeature(
						'encore',
						<IconV2 name="hourglass-solid" color="neutral-medium" size={16} />,
						<span className="CompaniesTable__statusPendingIcon">
							<Hourglass13x16 />
						</span>,
					)}
					<BodyText color="neutral-medium">{$.__('Pending')}</BodyText>
					</Flex>
				)}

				<TextButton onClick={takeToBankAccountSection} inline type="button">
					{$.__('Add Bank Account')}
				</TextButton>
			</Flex>
		);
	};

	const statusVerified = () => {
		return isPlaidAdoptionAndSufficientBalanceEnabled ? (
			<Pill icon="hourglass-solid" type="Brand">
				{$.__('Verified')}
			</Pill>
		) : (
			<Flex gap={0.75} alignItems="center">
				{ifFeature(
					'encore',
					<IconV2 name="circle-check-solid" color="success-strong" size={16} />,
					<div className="CompaniesTable__statusVerified">
						<CheckCircle14x14 />
					</div>,
				)}
				<BodyText color="success-strong">{$.__('Verified')}</BodyText>
			</Flex>
		);
	};

	const renderStatus = (row) => {
		const state = row.recordId
			? COMPANY_APP_STATE.PRE_EIN
			: getAppStateFromData(row);

		switch (state) {
			case COMPANY_APP_STATE.PRE_EIN:
				return null;

			case COMPANY_APP_STATE.COMPANY_SAVED:
				return statusCompanySaved(row);

			case COMPANY_APP_STATE.DOCS_DOWNLOADED:
				return statusDocsDownloaded(row);

			case COMPANY_APP_STATE.DOCS_UPLOADED:
				return statusDocsUploaded(row);

			case COMPANY_APP_STATE.VERIFIED:
				return statusVerified(row);

			default:
				break;
		}
	};

	const formatAddress = (row) => {
		if (!row.street1) {
			return null;
		}

		return (
			<div>
				<div>{row.street1}</div>
				<div>
					{row.city}, {row.state} {row.zip}
				</div>
			</div>
		);
	};

	const getRowsWithPotentials = () => {
		const { companies, addedEinsSuggestedNames } = props;

		const potentials = addedEinsSuggestedNames.map((suggested) => {
			return {
				companyLegalName: suggested.suggestedLegalName,
				recordId: suggested.recordId,
				isPotentialEin: true,
			};
		});

		return [...companies].concat(potentials);
	};

	const fullRows = getRowsWithPotentials();

	return (
		<Table
			caption={$.__('List of Employees')}
			columns={COLUMNS}
			rowKey={(row) => {
				return row.clientId ? row.clientId : row.recordId;
			}}
			rows={fullRows}
		/>
	);
}
