import { DatePickerProvider, ThemeProvider } from '@bamboohr/fabric';
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';

import { nextClassPrefix } from './_utils/next-class-prefix';
import {
	getLocale,
	getFormatTypeFromGlobalDateMask,
} from './_utils/date-picker.util';
import { getThemeName } from 'get-theme-name.util';

let uniqueClassPrefix = 'a';

/** Creates a unique class name prefix to avoid conflicting class names in JSS. */
function generateClassNamePrefix() {
	uniqueClassPrefix = nextClassPrefix(uniqueClassPrefix);
	return `jss-${uniqueClassPrefix}`;
}

/**
 * @param {React.SFCElement<any> | React.SFCElement<any>[]} children
 * @param {Element | DocumentFragment | null} attachedElement - `container` for `ReactDOM.render`
 * @param {() => void} callback
 * @param {ThemeProviderProps} themeProps
 * @param {boolean} safe - Defaults to `false`. Set to `true` to avoid error if `attachedElement` (container) is possibly `null`
 */
export const render = (
	children,
	attachedElement,
	callback,
	themeProps,
	safe = false,
) => {
	if (safe && !attachedElement) {
		console.error(
			"'attachedElement' provided to 'render' was 'null' or 'undefined'",
		);
		return;
	}
	const { mode, themeName } = getThemeName();

	ReactDOM.render(
		<RecoilRoot>
			<ThemeProvider
				classNamePrefix={generateClassNamePrefix()}
				{...themeProps}
				mode={mode}
				themeName={themeName}
			>
				<DatePickerProvider
					formatType={getFormatTypeFromGlobalDateMask()}
					locale={getLocale()}
				>
					{children}
				</DatePickerProvider>
			</ThemeProvider>
		</RecoilRoot>,
		attachedElement,
		callback,
	);
};

export default {
	render,
};
