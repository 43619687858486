import { Fragment, useState } from 'react';

import { CompanyInfoHeader } from '../../../components/company-info-header';
import { CompanyInfoForm } from '../../../components/company-info-form';
import { InfoCardManager } from '../../../components/info-card-manager';
import { UploadFormsModal } from '../../../components/upload-forms-modal';

import { uuidGenerator } from '../../../utils/shared';

export function DocsDownloadedStateComponent(props) {
	const {
		isProcessing,
		onSubmit,
		onInputChange,
		stateList,
		contacts,
		company,
		statuses,
		onUploadForms,
		revokePreviousContactPermission,
		onRevokePreviousContactPermission,
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<Fragment>
			<CompanyInfoHeader anotherEinLink={true} hasStatus={true} />

			<InfoCardManager
				buttonFunction={setIsModalOpen}
				clientId={company.clientId}
				company={company}
				isProcessing={isProcessing}
				statuses={[statuses]}
			/>

			<CompanyInfoForm
				contactList={contacts}
				data={company}
				isProcessing={isProcessing}
				onInputChange={onInputChange}
				onSubmit={onSubmit}
				stateList={stateList}
				revokePreviousContactPermission={revokePreviousContactPermission}
				onRevokePreviousContactPermission={onRevokePreviousContactPermission}
			/>

			<UploadFormsModal
				clientId={company.clientId}
				company={company}
				key={uuidGenerator()}
				onClose={() => {
					setIsModalOpen(false);
				}}
				onSubmit={onUploadForms}
				type={'company'}
				visible={isModalOpen}
			/>
		</Fragment>
	);
}
