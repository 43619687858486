import { Fragment, useState } from 'react';
import { isEnabled } from '@bamboohr/utils/lib/feature';

import { BankAccountForm } from '../../../components/bank-account-form';
import { BankInfoHeader } from '../../../components/bank-info-header';

import { InfoCardManager } from '../../../../company-information.react/components/info-card-manager';
import { StatusRow } from '../../../../company-information.react/components/status-row';
import { UploadFormsModal } from '../../../../company-information.react/components/upload-forms-modal';

import { uuidGenerator } from '../../../../company-information.react/utils/shared';
import { BANK_APP_STATE } from '../../../utils/shared';
import { LayoutBox } from '@bamboohr/fabric';
import { MultiBanksTable } from '../../../components/multi-banks-table';

export function DocsDownloadedStateComponent(props) {
	const isPlaidAdoptionAndSufficientBalanceEnabled = isEnabled(
		'PLAID_ADOPTION_AND_SUFFICIENT_BALANCE',
	);
	const {
		company,
		onSubmit,
		isProcessing,
		onUploadForms,
		onInputChange,
		plaidLinkToken,
		handlePlaidUpdateSuccess,
		popDownloadFormsModal,
		popUploadFormsModal,
		popVerificationsModal,
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<Fragment>
			<BankInfoHeader noBorder={true} />

			<InfoCardManager
				buttonFunction={setIsModalOpen}
				clientId={company.clientId}
				company={company}
				isProcessing={isProcessing}
				onFileDownload={() => {}}
				statuses={[BANK_APP_STATE.DOCS_DOWNLOADED]}
				plaidLinkToken={plaidLinkToken}
				handleLinkSuccess={handlePlaidUpdateSuccess}
				handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
			/>

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<LayoutBox height="24px" />
			) : (
				<StatusRow status={BANK_APP_STATE.BANK_SAVED} />
			)}

			{isPlaidAdoptionAndSufficientBalanceEnabled ? (
				<MultiBanksTable
					company={company}
					handleOpenDownloadModal={popDownloadFormsModal}
					handleOpenUploadModal={popUploadFormsModal}
					handleOpenVerificationModal={popVerificationsModal}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
				/>
			) : (
				<BankAccountForm
					bankAccount={company.bankAccounts[0]}
					company={company}
					isEdit={true}
					isProcessing={isProcessing}
					onInputChange={onInputChange}
					onSubmit={onSubmit}
					plaidLinkToken={plaidLinkToken}
					handlePlaidUpdateSuccess={handlePlaidUpdateSuccess}
				/>
			)}

			<UploadFormsModal
				bank={company.bankAccounts[0]}
				clientId={company.clientId}
				key={uuidGenerator()}
				onClose={() => {
					setIsModalOpen(false);
				}}
				onSubmit={onUploadForms}
				type="bank"
				visible={isModalOpen}
			/>
		</Fragment>
	);
}
