import { render } from 'base/wrapped-render';
import { useEffect, useRef } from 'react';
import { ResourcePicker } from './resource-picker';

type Resource = {
	name: string;
	url: string;
	sessionOnly: boolean;
	type: 'committed' | 'custom' | 'development' | 'odi';
	version?: 'dev' | 'prod' | 'stage';
};
type UseResourcePickerConfig = {
	env: 'dev' | 'prod' | 'stage';
	getUrl: (config: { type: Resource['type']; version: string }) => string;
	icon: string;
	initialResource: Resource;
	onChange: (resource: Resource) => void;
};

type UseResourcePickerChangeEvent = CustomEventInit<Resource>;

function createElementWithId(id: string) {
	const element = document.createElement('div');
	element.id = id;
	return element;
}

function getResourcePickerId(name: string) {
	return `js-${name}MicroFrontendResourcePicker`;
}

export function useResourcePicker(config: UseResourcePickerConfig) {
	const configRef = useRef<UseResourcePickerConfig>(null);
	configRef.current = config;

	useEffect(() => {
		const { env, getUrl, icon, onChange, initialResource } = configRef.current;

		const { name } = initialResource;

		const resourcePickerId = getResourcePickerId(name);

		if (env !== 'dev') {
			return;
		}

		if (!document.getElementById(resourcePickerId)) {
			const resourcePickerElement = createElementWithId(resourcePickerId);

			document.body.appendChild(resourcePickerElement);

			render(
				<ResourcePicker
					getUrl={getUrl}
					icon={icon}
					initialResource={initialResource}
				/>,
				resourcePickerElement,
			);
		}

		const handleChange = (e: UseResourcePickerChangeEvent) => {
			if (typeof onChange === 'function') {
				onChange(e.detail);
			}
		};

		document.addEventListener('ResourcePicker:change', handleChange);

		return () => {
			document.removeEventListener('ResourcePicker:change', handleChange);
		};
	}, []);
}
