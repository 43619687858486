import { LayoutBox } from '@bamboohr/fabric';

import React from 'react';

import { useResourcePicker } from '../resources';

export const VersionManager = (props) => {
	const { children, getUrl, icon, initialResource, onChange } = props;
	const env = window.ENV;

	useResourcePicker({ env, getUrl, icon, initialResource, onChange });

	return env === 'dev' ? (
		<LayoutBox height="100%" width="100%">
			{children}
		</LayoutBox>
	) : (
		children
	);
};
