import { useState } from 'react';
import moment from 'moment';
import { BodyText, DatePicker, LayoutBox, makeStyles, RadioGroup, SelectField, InlineMessage, InlineMessageStatusType } from '@bamboohr/fabric';
import { CancellationContentProps, PayrollFormData } from '../../types';
import { ModalContentWrapper } from '../modal-content-wrapper';
import { FEDERAL_ID_OPTIONS, QUARTER_SELECT_OPTIONS, YEAR_SELECT_OPTIONS } from './constants';
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @startCleanup encore
const useStyles = makeStyles(({ spacing }) => ({
	marginBottom: {
		marginBottom: spacing(2),
	},
}));
// @endCleanup encore

export const PayrollCancellationFormStep1 = ({ onNext, isCancellingAccount, ...rest }: CancellationContentProps): JSX.Element => {
	const [formValues, setFormValues] = useState<PayrollFormData>({
		final_pay_date: null,
		use_federal_id_after_final_date: null,
		pay_through_quarter: null,
		pay_through_year: null,
	});
	// @startCleanup encore
	const classes = useStyles();
	// @endCleanup encore

	const mergeFormValues = (values: PayrollFormData) => {
		setFormValues({ ...formValues, ...values });
	};

	const handleOnNext = () => {
		onNext({ payroll_data: formValues });
	};

	const disableNextButton =
		!formValues.final_pay_date ||
		!formValues.use_federal_id_after_final_date ||
		!formValues.pay_through_quarter ||
		!formValues.pay_through_year;

	return ifFeature(
		'encore',
		<ModalContentWrapper {...rest} disablePrimaryButton={disableNextButton} onNext={handleOnNext}>
			<LayoutBox marginBottom={2}>
				<BodyText>
					{$.__("We'll be sad to see you go! Please fill out the following and we will be in touch to process your cancellation.")}
				</BodyText>
			</LayoutBox>
			<form>
				{isCancellingAccount && (
					<LayoutBox marginBottom={1}>
						<InlineMessage
							icon="circle-info-solid"
							status={InlineMessageStatusType.info}
							title={$.__(
								'Your final pay date must be before your desired cancellation date',
							)}
						/>
					</LayoutBox>
				)}
				<LayoutBox marginBottom={2}>
					<DatePicker
						id='final_pay_date'
						label={$.__('Final Pay Date')}
						minDate={moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')}
						onChange={(param) => mergeFormValues({ final_pay_date: param.value })}
						required={true}
						value={formValues.final_pay_date}
					/>
				</LayoutBox>
				<LayoutBox marginBottom={2}>
					<RadioGroup
						items={FEDERAL_ID_OPTIONS}
						label={$.__('Will you be processing payroll using this Federal ID after your final pay date?')}
						name='use_federal_id_after_final_date'
						onChange={(param) =>
							mergeFormValues({ use_federal_id_after_final_date: param.value as PayrollFormData['use_federal_id_after_final_date'] })
						}
						value={formValues.use_federal_id_after_final_date || ''}
					/>
				</LayoutBox>
				<LayoutBox marginBottom={2}>
					<SelectField
						id='pay_through_quarter'
						items={QUARTER_SELECT_OPTIONS}
						isClearable={false}
						label={$.__('Please file taxes through quarter:')}
						onChange={(e) => {
							const value = e.target.value[0];
							mergeFormValues({
								pay_through_quarter: value,
								pay_through_year: value !== '4' ? YEAR_SELECT_OPTIONS[1].value : null,
							});
						}}
						value={[formValues.pay_through_quarter || '']}
						width={6}
					/>
				</LayoutBox>
				<LayoutBox marginBottom={2}>
					<SelectField
						id='pay_through_year'
						items={formValues.pay_through_quarter === '4' ? YEAR_SELECT_OPTIONS : [YEAR_SELECT_OPTIONS[1]]}
						isClearable={false}
						isDisabled={!formValues.pay_through_quarter}
						label={$.__('Please file taxes through year:')}
						onChange={(e) => mergeFormValues({ pay_through_year: e.target.value[0] })}
						value={[formValues.pay_through_year || '']}
						width={6}
					/>
				</LayoutBox>
			</form>
		</ModalContentWrapper>,
		<ModalContentWrapper {...rest} disablePrimaryButton={disableNextButton} onNext={handleOnNext}>
			<p className={classes.marginBottom}>
				{$.__("We'll be sad to see you go! Please fill out the following and we will be in touch to process your cancellation.")}
			</p>
			<form>
				<div className={classes.marginBottom}>
					<DatePicker
						id='final_pay_date'
						label={$.__('Final Pay Date')}
						minDate={moment().add(1, 'days').format('YYYY-MM-DD')}
						onChange={(param) => mergeFormValues({ final_pay_date: param.value })}
						required={true}
						value={formValues.final_pay_date}
					/>
				</div>

				<div className={classes.marginBottom}>
					<RadioGroup
						items={FEDERAL_ID_OPTIONS}
						label={$.__('Will you be processing payroll using this Federal ID after your final pay date?')}
						name='use_federal_id_after_final_date'
						onChange={(param) =>
							mergeFormValues({ use_federal_id_after_final_date: param.value as PayrollFormData['use_federal_id_after_final_date'] })
						}
						value={formValues.use_federal_id_after_final_date || ''}
					/>
				</div>

				<div className={classes.marginBottom}>
					<SelectField
						id='pay_through_quarter'
						items={QUARTER_SELECT_OPTIONS}
						isClearable={false}
						label={$.__('Please file taxes through quarter:')}
						onChange={(e) => {
							const value = e.target.value[0];
							mergeFormValues({
								pay_through_quarter: value,
								pay_through_year: value !== '4' ? YEAR_SELECT_OPTIONS[1].value : null,
							});
						}}
						value={[formValues.pay_through_quarter || '']}
						width={6}
					/>
				</div>

				<div className={classes.marginBottom}>
					<SelectField
						id='pay_through_year'
						items={formValues.pay_through_quarter === '4' ? YEAR_SELECT_OPTIONS : [YEAR_SELECT_OPTIONS[1]]}
						isClearable={false}
						isDisabled={!formValues.pay_through_quarter}
						label={$.__('Please file taxes through year:')}
						onChange={(e) => mergeFormValues({ pay_through_year: e.target.value[0] })}
						value={[formValues.pay_through_year || '']}
						width={6}
					/>
				</div>
			</form>
		</ModalContentWrapper>
	);
};
