import React, { useState, useEffect } from 'react';
import { StandardModal, Button, TextButton, Select, Flex, Divider, Headline, LayoutBox, BodyText, Label } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { postNewActivationTrialValues, setTrialStarted } from 'time-tracking/http';
import { redirect } from 'base/_utils/BambooHR.util';
import { showSlidedown } from 'base/greenhouse/payroll-tools/lib';

type ActivationProps = {
	isActivationOpen: boolean;
	setIsActivationOpen: (boolean) => void;
};

const TrialActivationModal: React.FC<ActivationProps> = ({ isActivationOpen, setIsActivationOpen }) => {
	const [needsSelections, setNeedsSelections] = useState(true);
	const [selectedEmployeeCount, setSelectedEmployeeCount] = useState();
	const [selectedProcess, setSelectedProcess] = useState();
	const [isProcessing, setIsProcessing] = useState(false);

	useEffect(() => {
		if (selectedEmployeeCount && selectedProcess) {
			setNeedsSelections(false);
		}
	}, [selectedEmployeeCount, selectedProcess]);

	const employeeCounts = [
		{
			text: '1-24',
			value: '1-24',
		},
		{
			text: '25-50',
			value: '25-50',
		},
		{
			text: '51-100',
			value: '51-100',
		},
		{
			text: '101-250',
			value: '101-250',
		},
		{
			text: '251-500',
			value: '251-500',
		},
		{
			text: '500+',
			value: '500+',
		},
	];

	const currentTimeTrackingProcess = [
		{
			text: $.__('We use another time tracking product currently.'),
			value: 'use_another_product',
		},
		{
			text: $.__('We track time manually.'),
			value: 'track_manually',
		},
		{
			text: $.__('We want time tracking and are just getting started.'),
			value: 'want_time_tracking',
		},
	];

	const handleClose = () => {
		setIsActivationOpen(false);
	};

	const handleSubmit = () => {
		setIsProcessing(true);
		showSlidedown($.__('Getting your trial ready now! This may take a few moments, so hold tight.'), 'info');
		postNewActivationTrialValues({ employeeCount: selectedEmployeeCount, currentTimeTrackingProcess: selectedProcess })
			.then(() => {
				setTrialStarted();
				redirect('/settings/time_tracking');
			})
			.catch(() => {
				// @ts-ignore
				window.errorFallBack();
				setIsProcessing(false);
			});
	};

	const cancelBtn = (
		<TextButton key='button-2' onClick={handleClose} processing={isProcessing}>
			{$.__('Cancel')}
		</TextButton>
	);

	const submitBtn = (
		<Button
			data-bi-id='time-tracking-trial-start-trial-button'
			disabled={needsSelections}
			key='button-1'
			onClick={handleSubmit}
			processing={isProcessing}
		>
			{$.__(`Start Free Trial`)}
		</Button>
	);

	return (
		<StandardModal isOpen={isActivationOpen} onRequestClose={handleClose}>
			<StandardModal.Body
				renderFooter={<StandardModal.Footer actions={ifFeature('encore', [cancelBtn, submitBtn], [submitBtn, cancelBtn])} />}
				renderHeader={<StandardModal.Header title={$.__('Time Tracking Trial')} />}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline
						icon='stopwatch-regular'
						subtext={
							<Flex flexDirection='column' gap={2.5} paddingTop={2}>
								<BodyText justify='start'>
									{$.__(
										`This trial will allow you to access the full Time Tracking feature for 14 days. Add as many employees as you need to understand the impact this tool will have on your business.`
									)}
								</BodyText>
								<BodyText justify='start'>
									{$.__(
										`Once activated, a BambooHR representative will reach out shortly. This rep will be a dedicated resource for you to rely on should you have any questions.`
									)}
								</BodyText>
							</Flex>
						}
						text={$.__(`Try it Out For 14 Days!`)}
					/>
					<Divider color='neutral-extra-weak' marginY={3} />
					<LayoutBox marginBottom={2}>
						<Headline size='extra-small'>{$.__(`Enough about us. We'd like to know more about you`)}</Headline>
					</LayoutBox>
					<Flex flexDirection='column' gap={0.5} marginBottom={2}>
						<Label htmlFor='employeeNeeds'>{$.__(`Roughly how many of your employees need to track hours?*`)}</Label>
						<Select
							id='employeeNeeds'
							isClearable={false}
							items={employeeCounts}
							onSelect={(item) => setSelectedEmployeeCount(item)}
							selectedValues={[selectedEmployeeCount]}
							width={10}
						/>
					</Flex>
					<Flex flexDirection='column' gap={0.5}>
						<Label htmlFor='trackingProcess'>{$.__(`How would you describe your current time tracking process?*`)}</Label>
						<Select
							id='trackingProcess'
							isClearable={false}
							items={currentTimeTrackingProcess}
							onSelect={(item) => setSelectedProcess(item)}
							selectedValues={[selectedProcess]}
							width={10}
						/>
					</Flex>
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>
	);
};

export default TrialActivationModal;
