import Ajax from '@utils/ajax';
import { getFileUploadInfo, type FileUploadInfo } from './get-file-upload-info';

export interface UploadFileOptions {
	/**
	 * The info needed to upload the file. If not provided, it will be fetched.
	 */
	fileUploadInfo?: FileUploadInfo;
	/**
	 * Configuration for the AJAX request
	 */
	ajaxConfig?: object;
	/**
	 * The form data to send with the file
	 */
	formData?: FormData;
}

/**
 * Uploads a file.
 */
export async function uploadFile(
	file: File,
	options: UploadFileOptions = {},
): Promise<{ fileId: string }> {
	const { fileId, formData, url } =
		options.fileUploadInfo || (await getFileUploadInfo());

	formData.append('file', file);
	formData.append('x-amz-meta-file-name', file.name);

	await Ajax.post<void>(url, formData, options.ajaxConfig);

	return { fileId };
}

/**
 * @deprecated Use {@link uploadFile} instead.
 * Uploads a file with jQuery.ajax. This is an odd implementation to support existing code that needs an XHR object. Instead of making the actual request, it returns a function you need to call to get the XHR object.
 */
export async function getUploadFileXhr() {
	const { fileId, formData, url } = await getFileUploadInfo();
	return {
		fileId,
		uploadFile: (file: File, settings?: Partial<JQueryAjaxSettings>) => {
			formData.append('file', file);
			formData.append('x-amz-meta-file-name', file.name);
			return $.ajax({
				url,
				type: 'post',
				contentType: false,
				processData: false,
				data: formData,
				dataType: 'json',
				...settings,
			});
		},
	};
}
