import { Modal } from 'modal-legacy';

/**
 * @param {number} props.activeCount
 * @param {string} props.fieldTitle
 * @param {function} props.closeModal
 * @param {boolean} props.isOpen
 */
export function DeleteFixItModal(props) {
	const { activeCount, fieldTitle, closeModal, isOpen } = props;

	let headline = $.__(`You've got 1 employee assigned to this %1$s`, fieldTitle);
	if (activeCount > 1) {
		headline = $.__(`You've got %1$s employees in this %2$s`, activeCount, fieldTitle);
	}

	return (
		<Modal
			headline={headline}
			icon='triangle-exclamation-regular'
			iconColor='warning-medium'
			iconV2Color='warning-medium'
			isOpen={isOpen}
			onClose={closeModal}
			primaryAction={closeModal}
			primaryActionText={$.__("I'll Fix It")}
			title={$.__("Can't Delete Yet...")}
		>
			<p className='EmployeeFieldsForm__deleteWarning'>
				{$.__(`Don't leave them hanging; move them to another %1$s before deleting this.`, fieldTitle)}
			</p>
		</Modal>
	);
}
