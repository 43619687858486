import { ACA_TYPES } from 'aca-status-select.react';
import Ajax from '@utils/ajax';
import { EmploymentStatusTableRow } from './types';
import { ListValue } from '../types';
import { TableColumn } from '@bamboohr/fabric';

export function generateColumns(
	showAcaStatus: boolean,
	showPeople: boolean,
	handleEditClick: (row: EmploymentStatusTableRow) => void,
	handleDeleteClick: (row: EmploymentStatusTableRow) => void
): TableColumn<EmploymentStatusTableRow>[] {
	const columns: TableColumn<EmploymentStatusTableRow>[] = [
		{
			header: $.__('Employment Status'),
			cell: (row: EmploymentStatusTableRow): string => row.status,
			sortBy: (row: EmploymentStatusTableRow): string => row?.status?.toLowerCase(),
		},
	];

	if (showAcaStatus) {
		columns.push({
			header: $.__('ACA Status'),
			cell: (row: EmploymentStatusTableRow) => row.acaStatus,
			sortBy: (row: EmploymentStatusTableRow) => row?.acaStatus?.toLowerCase(),
		});
	}

	columns.push({
		header: $.__('FTE'),
		cell: (row: EmploymentStatusTableRow) => row.fte,
		sortBy: (row: EmploymentStatusTableRow) => row.fte,
	});

	if (showPeople) {
		columns.push({
			header: $.__('People'),
			cell: (row: EmploymentStatusTableRow) => row.people,
			sortBy: (row: EmploymentStatusTableRow): number => row.peopleCount,
		});
	}

	columns.push({
		headerAriaLabel: $.__('Edit or delete'),
		showOnHover: true,
		cell: {
			type: 'actions',
			actions: (row: EmploymentStatusTableRow) => [
				{
					icon: 'pen-regular',
					action: row.isEditable ? () => handleEditClick(row) : null,
					tooltipContent: $.__('Edit'),
				},
				{
					icon: 'trash-regular',
					action: row.isDeletable ? () => handleDeleteClick(row) : null,
					tooltipContent: $.__('Delete'),
				},
			],
		},
	});

	return columns;
}

export function getEditValues(selectedItem: ListValue | undefined) {
	if (!selectedItem) {
		return {
			name: '',
			acaStatus: '',
			fte: '',
		};
	}

	return {
		name: selectedItem.label,
		acaStatus: selectedItem.aca_status ? selectedItem.aca_status : '',
		fte: selectedItem.FTEValue ? window.formatNumberJS(Number(selectedItem.FTEValue), 2) : '',
	};
}

export function getShowChangeHistory(totalHistoryCount: number): boolean {
	return totalHistoryCount > 0;
}

export function getTotalHistoryCount(listId, listValueId) {
	return Ajax.get('/ajax/manage/list.php', {
		id: listValueId,
		vid: listId,
	});
}

export function getAcaStatusLabel(acaStatus: string | undefined): string {
	if (!acaStatus) {
		return '';
	}

	return ACA_TYPES[acaStatus] ? ACA_TYPES[acaStatus].label : '';
}
