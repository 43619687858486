import moment from 'moment.lib';
import {
	BodyText,
	Dropdown,
	Flex,
	IconV2,
	LayoutBox,
	TextButton,
	Tooltip,
} from '@bamboohr/fabric';
import { getFormattedCurrency } from '../getFormattedCurrency';

export function getColumns({ billingCurrency }) {

	function handleDownloadSelect({ billingDate, downloadUrl, value }) {
		if (value === 'downloadInvoicePDF') {
			window.location.assign(
				`/settings/account/billing/invoices/download?url=${downloadUrl}`,
			);
		}
		if (value === 'downloadEmployeesCSV') {
			window.location.assign(
				`/settings/account/billing/invoices/employees?date=${billingDate}`,
			);
		}
	}

	function getDownloadDropdownItems({
		hasActiveEmployeeDownload,
		shouldShowActiveEmployees,
	}) {
		if (shouldShowActiveEmployees) {
			return [
				{
					icon: <IconV2 name="down-to-line-regular" size={16} />,
					text: $.__('Download Invoice (PDF)'),
					value: 'downloadInvoicePDF',
				},
				{
					icon: <IconV2 name="down-to-line-regular" size={16} />,
					isDisabled: !hasActiveEmployeeDownload,
					text: $.__('Download Active Employees (CSV)'),
					value: 'downloadEmployeesCSV',
				},
			];
		}
		return [
			{
				icon: <IconV2 name="down-to-line-regular" size={16} />,
				text: $.__('Download Invoice (PDF)'),
				value: 'downloadInvoicePDF',
			},
		];
	}

	return [
			{
				cell: {
					type: 'checkbox',
					selected: (row) => row.selected,
					disabled: (row) => row.disabled,
				},
				header: { type: 'checkbox' },
				headerAriaLabel: $.__('Select Invoices'),
				key: 'selectInvoices',
				width: '48px',
			},
			{
				cell: ({ billingDate }) => moment(billingDate).utc().format('MMM D, YYYY'),
				header: $.__('Billing Date'),
				key: 'billingDate',
				sortBy: ({ billingDate }) => billingDate,
				width: '25%',
			},
			{
				cell: ({ amount }) => getFormattedCurrency({ amount, billingCurrency }),
				header: $.__('Amount'),
				key: 'billingAmount',
				width: '25%',
			},
			{
				cell: ({ downloadUrl, billingDate, invoiceNumber }) => {
					const number = invoiceNumber.replace('INV', '');
					const date = moment(billingDate).format('MMDDYYYY');

					return downloadUrl ? (
						<Tooltip content={$.__('Preview Invoice')} enterDelay={500}>
							<TextButton
								href={`/settings/account/billing/invoices/preview?url=${downloadUrl}&invoiceNumber=${number}&date=${date}`}
								target="_blank"
								type="button"
							>
								{invoiceNumber}
							</TextButton>
						</Tooltip>
					) : (
						<Flex>
							<BodyText>{$.__('Creating Invoice')}</BodyText>
							<LayoutBox marginLeft={0.75} marginTop={0.25}>
								<Tooltip
									content={$.__(
										'Invoices may take up to 12 hours to show up here.',
									)}
								>
									<span role="button" tabIndex="0">
										<IconV2
											color="neutral-medium"
											name="circle-question-regular"
											size={16}
										/>
									</span>
								</Tooltip>
							</LayoutBox>
						</Flex>
					);
				},
				cellPaddingReset: 'vertical',
				header: $.__('Invoice #'),
				key: 'billingInvoiceNumber',
				verticalAlign: 'center',
				width: '25%',
			},
			{
				align: 'right',
				headerAriaLabel: 'Download Actions',
				key: 'downloadActions',
				showOnHover: true,
				cellPaddingReset: 'vertical',
				cell: ({
					billingDate,
					downloadUrl,
					hasActiveEmployeeDownload,
					shouldShowActiveEmployees,
				}) => {
					return (
						<LayoutBox paddingY={1}>
							<Dropdown
								ButtonProps={{
									color: 'secondary',
									icon: <IconV2 name="ellipsis-vertical-regular" size={16} />,
									size: 'small',
									variant: 'outlined',
								}}
								biId='settings-billing-invoice-history'
								items={getDownloadDropdownItems({
									hasActiveEmployeeDownload,
									shouldShowActiveEmployees,
								})}
								onSelect={(value) => handleDownloadSelect({ billingDate, downloadUrl, value })}
								showCaret={false}
							/>
						</LayoutBox>
					);
				},
				width: '25%',
			},
		]
}
