import { Icon, IconV2, makeStyles, Tooltip } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import classnames from 'classnames';
import { upperFirst } from 'lodash';
import { useState } from 'react';
import { getResourceUrl } from '../get-resource-url';
import { ResourcePickerModal } from './resource-picker-modal';

const useStyles = makeStyles(({ zIndex, palette }) => ({
	root: {
		position: 'fixed',
		bottom: 0,
		right: 0,
		zIndex: zIndex.snackbar,
		width: 40,
		height: 40,
		overflow: 'hidden',

		'&:hover $icon': {
			fill: palette.primary.main,
		},

		'&:hover $nameIcon': {
			display: 'inline-block',
		},

		'&:hover $typeIcon': {
			display: 'none',
		},

		'&:hover $bubble': {
			borderColor: palette.primary.main,
			boxShadow: '0 0 0px 2px white',
		},
	},
	bubble: {
		position: 'absolute',
		backgroundColor: palette.common.white,
		border: `1px solid ${palette.grey[200]}`,
		borderRadius: '50% 50% 0 0',
		cursor: 'pointer',
		height: 75,
		left: 0,
		outline: 'none',
		top: 0,
		width: 75,
	},
	icon: {
		display: 'flex',
		bottom: 8,
		height: 16,
		justifyContent: 'center',
		lineHeight: 0,
		pointerEvents: 'none',
		position: 'absolute',
		right: 8,
		width: 16,
	},
	nameIcon: {
		display: 'none',
		fill: palette.primary.main,
	},
	pulse: {
		'&:not(:hover)': {
			animation: `$pulse 1500ms infinite alternate`,
		},
	},
	typeIcon: {
		fill: palette.grey[600],
	},
	'@keyframes pulse': {
		'0%': {
			opacity: 1,
		},
		'100%': {
			opacity: 0.25,
		},
	},
}));

// @startCleanup encore
const jadeIcons = {
	committed: 'fab-check-circle-outline-16x16',
	development: 'fab-wrench-16x16',
	odi: 'fab-clock-14x14',
	custom: 'fab-pencil-16x16',
};
// @endCleanup encore

const icons = {
	committed: 'circle-check-regular',
	development: 'wrench-regular',
	odi: 'clock-regular',
	custom: 'pen-regular',
};

export function ResourcePicker(props) {
	const { getUrl, icon, onChange, initialResource } = props;

	const classes = useStyles(props);

	const [isPickingResource, setIsPickingResource] = useState(false);
	const [resource, setResource] = useState(initialResource);

	const { name, type, version } = resource;

	// @startCleanup encore
	const jadeTypeIcon = <Icon name={jadeIcons[type]} />;
	// @endCleanup encore
	const typeIcon = <IconV2 name={icons[type]} size={16} />;

	return (
		<div className={classes.root}>
			<Tooltip
				content={version}
				title={`Using the ${type} version of ${upperFirst(name)}.`}
			>
				{/* eslint-disable jsx-a11y/control-has-associated-label */}
				<button
					aria-label="version picker"
					className={classes.bubble}
					onClick={() => setIsPickingResource(true)}
					type="button"
				></button>
				{/* eslint-enable jsx-a11y/control-has-associated-label */}
			</Tooltip>

			<span
				className={classnames(classes.icon, {
					[classes.pulse]: type !== 'committed',
				})}
			>
				<span className={classes.typeIcon}>
					{ifFeature('encore', typeIcon, jadeTypeIcon)}
				</span>
				<span className={classes.nameIcon}>
					{icon || ifFeature('encore', typeIcon, jadeTypeIcon)}
				</span>
			</span>

			<ResourcePickerModal
				getUrl={getUrl || getResourceUrl(name)}
				isOpen={isPickingResource}
				onCancel={() => setIsPickingResource(false)}
				onSave={(_resource) => {
					setIsPickingResource(false);

					setResource(_resource);

					document.dispatchEvent(
						new CustomEvent('ResourcePicker:change', { detail: _resource }),
					);

					if (typeof onChange === 'function') {
						onChange(_resource);
					}
				}}
				value={resource}
			/>
		</div>
	);
}
